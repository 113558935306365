import React, { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { openNotification } from '../../utils';
import UserService from '../../services/UserService';
import ImageService from '../../services/ImageService';

const GoogleSign = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const hasRequested = useRef(false); // istek kontrolü için useRef kullanımı

  useEffect(() => {
    if (hasRequested.current) return; // Eğer istek daha önce yapılmışsa çık
    hasRequested.current = true; // İstek yapılacağını işaretle

    const searchParams = new URLSearchParams(location.search);
    const state = searchParams.get('state');
    const code = searchParams.get('code');

    if (state && code) {
      axios.post(`${process.env.REACT_APP_API_BASE_URL}/oauth2/token`, {
        provider: 'GOOGLE',
        state: state,
        code: code,
      })
      .then((response) => {
        // Token bilgilerini kaydetme
        localStorage.setItem('accessToken', response.data.accessToken);
        localStorage.setItem('accessTokenExpiredAt', response.data.accessTokenExpiredAt);
        localStorage.setItem('refreshToken', response.data.refreshToken);
        localStorage.setItem('refreshTokenExpiredAt', response.data.refreshTokenExpiredAt);

        // Kullanıcı bilgilerini ve izinleri alıp yerel depolamaya kaydetme
        userGet();
      })
      .catch((error) => {
        console.error('Token alma başarısız:', error);
        navigate('/login'); // Hata durumunda login sayfasına yönlendir
      });
    } else {
      console.error('Kod veya state eksik');
      navigate('/login'); // Gerekli parametreler yoksa login sayfasına yönlendir
    }
  }, [location.search, navigate]); // Bağımlılıkları kontrol et

  const userGet = () => {
    UserService.getMe()
      .then((response: any) => {
        console.log(response);

        // Kullanıcı bilgilerini yerel depolama alanına kaydetme
        localStorage.setItem('firstName', response.data.firstName);
        localStorage.setItem('lastName', response.data.lastName);
        localStorage.setItem('email', response.data.email);
        localStorage.setItem('phoneNumber', response.data.phoneNumber);
        localStorage.setItem('id', response.data.id);


        const fieldsWeCareAbout = [
          'firstName',
          'lastName',
          'email',
          'phoneNumber',
          'iban',
          'address',
          'identityNumber',
        ];
      
        let filledCount = 0;
        fieldsWeCareAbout.forEach((field) => {
          if (response.data[field]) {
            filledCount++;
          }
        });
      
        const totalRequiredFields = fieldsWeCareAbout.length; // 7
        const profileCompletion = (filledCount / totalRequiredFields) * 100;
        localStorage.setItem('profileCompletion', profileCompletion.toString());
        localStorage.setItem('isPartner', response.data.isPartner);
        
        // Rol ve izin bilgilerini kaydetme
        const roles = response.data.roles;
        localStorage.setItem('roles', JSON.stringify(roles));

        const getImage = async () => {
          console.log(response.data.profilePhoto);
          if (response.data.profilePhoto) {
            const imageResponse: any = await ImageService.getImage(response.data.profilePhoto);
            const imageUrl = imageResponse?.data?.preSignUrl;
            localStorage.setItem('profilePhotoBase64', imageUrl);
          }
        }
        getImage();

        if (roles && roles.length > 0) {
          const permissions = roles.flatMap((role: any) => role.permissions.map((permission: any) => permission.name));
          localStorage.setItem('permissions', JSON.stringify(permissions));
        }

        // Kullanıcı rolüne göre yönlendirme
        const userRole = roles?.[0]?.name;
        if (userRole === 'STUDENT') {
          navigate('/');
        } else {
          navigate('/'); // Admin kullanıcılar için farklı bir sayfaya yönlendirme
        }
      }).catch((e: Error) => {
        openNotification('error', 'Başarısız', 'Giriş yapılamadı.');
        localStorage.clear();
        console.log(e);
      });
  };

  return null;
};

export default GoogleSign;