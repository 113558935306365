import React, { useState, useEffect, useCallback } from 'react';
import {
    Button, Row, Table, Typography, Popconfirm, Tag, Card, Input, message,
} from 'antd';
import { PoweroffOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';
import type { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { debounce } from 'lodash';
import UserService from '../../services/UserService'; // Servis

const { Title } = Typography;

export const UserList = () => {
    const [users, setUsers] = useState<any[]>([]);
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState<string>('');
    
    // Sort alanlarını yönetmek için ek state
    const [sortField, setSortField] = useState<string>('createdAt');  // varsayılan sort alanı
    const [sortDirection, setSortDirection] = useState<string>('DESC'); // varsayılan sort yönü
    
    const [pagination, setPagination] = useState<any>({
        current: 1,
        pageSize: 10,
        total: 0,
    });
    const navigate = useNavigate();

    /**
     * Sunucudan veriyi çekecek fonksiyon.
     * Arama, sayfa, boyut, sort alanlarını backend'e gönderiyoruz.
     */
    const getUsers = useCallback(
        (search?: string, page = 1, pageSize = 10, sField = 'createdAt', sDirection = 'DESC') => {
            setLoading(true);
            UserService.getAll({
                name: search,
                page: page - 1,   // Spring'de sayfa 0'dan başladığı için -1
                size: pageSize,
                sortField: sField,
                sortDirection: sDirection,
            })
                .then((response: any) => {
                    setUsers(response.data.content);
                    setPagination((prev: any) => ({
                        ...prev,
                        current: page,
                        pageSize,
                        total: response.data.totalElements,
                    }));
                    setLoading(false);
                })
                .catch((e) => {
                    console.error(e);
                    setLoading(false);
                });
        },
        []
    );

    // İlk render + dependency değişimlerinde veriyi çek
    useEffect(() => {
        getUsers(searchTerm, pagination.current, pagination.pageSize, sortField, sortDirection);
    }, [pagination.current, pagination.pageSize, searchTerm, sortField, sortDirection, getUsers]);

    // Kullanıcı statüsünü (Aktif/Pasif) değiştirme
    const toggleUserStatus = async (id: number, isActive: boolean) => {
        try {
            await UserService.toggleStatus(id, !isActive);
            message.success(`Kullanıcı ${!isActive ? 'aktif' : 'pasif'} hale getirildi.`);
            // Listeyi yeniden getir
            getUsers(searchTerm, pagination.current, pagination.pageSize, sortField, sortDirection);
        } catch (error) {
            console.error(error);
            message.error('İşlem sırasında bir hata oluştu.');
        }
    };

    // Arama girişini debounced şekilde yönetmek
    const debouncedSearch = useCallback(
        debounce((value: string) => {
            // Yeni arama -> sayfa 1'e dön
            setPagination((prev: any) => ({ ...prev, current: 1 }));
            setSearchTerm(value);
        }, 300),
        []
    );

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        debouncedSearch(event.target.value);
    };

    useEffect(() => {
        return () => {
            debouncedSearch.cancel();
        };
    }, [debouncedSearch]);

    /**
     * Tablonun sayfa/sıralama/filtre değişimlerini yakalayan fonksiyon.
     * sorter -> { field, order: 'ascend' | 'descend' | undefined }
     */
    const handleTableChange = (
        paginationConfig: TablePaginationConfig,
        _filters: any,
        sorter: any | any[]
    ) => {
        let sField = sortField;
        let sDirection = sortDirection;

        // Tek sorter objesi ise:
        if (!Array.isArray(sorter)) {
            if (sorter.field) {
                sField = sorter.field.toString();
                sDirection = sorter.order === 'ascend' ? 'ASC' : 'DESC';
            }
        }

        setSortField(sField);
        setSortDirection(sDirection);

        setPagination({
            ...pagination,
            current: paginationConfig.current,
            pageSize: paginationConfig.pageSize || 10,
        });
    };

    const handleDetail = (id: string) => {
        navigate(`/users/${id}`);
    };

    /**
     * Sütunlar:
     * - 'status' (boolean) ve 'lastSuccessfulLogin' alanlarına sorter: true ekledik.
     */
    const columns: ColumnsType<any> = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            // sorter: true, // Sunucu taraflı sıralama (sortField='id')
        },
        {
            title: 'Ad Soyad',
            dataIndex: 'firstName', // Sıralama firstName üzerinde
            key: 'firstName',
            sorter: true,
            render: (value, record) => `${record.firstName} ${record.lastName}`,
        },
        {
            title: 'E-posta',
            dataIndex: 'email',
            key: 'email',
            sorter: true,
        },
        {
            title: 'Telefon',
            dataIndex: 'phoneNumber',
            key: 'phoneNumber',
            sorter: false,
        },
        {
            title: 'Rol',
            key: 'roles',
            sorter: false,
            render: (_, record) => (
                <span>
                    {record.roles
                        .map((role: any) => (role.name === 'CUSTOMER' ? 'İş Ortağı' : 'Admin'))
                        .join(', ')}
                </span>
            ),
        },
        {
            title: 'Katılma Tarihi',
            dataIndex: 'createdAt',
            key: 'createdAt',
            sorter: true,
            render: (createdAt) => moment(createdAt).format('DD/MM/YYYY HH:mm'),
        },
        {
            title: 'Statü',
            dataIndex: 'status',
            key: 'status',
            sorter: true, // boolean alan, sunucu sıralamada sortField='status'
            render: (value, record) => (
                <Tag color={record.status ? 'green' : 'red'}>
                    {record.status ? 'Aktif' : 'Pasif'}
                </Tag>
            ),
        },
        {
            title: 'Son Giriş Zamanı',
            dataIndex: 'lastSuccessfulLogin',
            key: 'lastSuccessfulLogin',
            sorter: true, // sunucu sıralamada sortField='lastSuccessfulLogin'
            render: (lastSuccessfulLogin) => {
                if (!lastSuccessfulLogin) {
                    return <Tag color="default">Henüz giriş yapmamış</Tag>;
                }
                return (
                    <Tag color="default">
                        {moment(lastSuccessfulLogin).fromNow()}
                    </Tag>
                );
            },
        },
        {
            title: 'İşlem',
            key: 'action',
            render: (_, record) => (
                <div>
                    <Button
                        shape="circle"
                        icon={<EditOutlined />}
                        className="icon-button mr-10px"
                        onClick={() => handleDetail(record.id)}
                    />
                    <Popconfirm
                        title={`Bu kullanıcıyı ${record.status ? 'pasif' : 'aktif'} hale getirmek istiyor musunuz?`}
                        onConfirm={() => toggleUserStatus(record.id, record.status)}
                        okText="Evet"
                        cancelText="Hayır"
                    >
                        <Button
                            shape="circle"
                            className="icon-button"
                            icon={
                                record.status ? (
                                    <PoweroffOutlined style={{ color: 'green' }} />
                                ) : (
                                    <PoweroffOutlined style={{ color: 'red' }} />
                                )
                            }
                        />
                    </Popconfirm>
                </div>
            ),
        },
    ];

    return (
        <Card className="br-30px mb-120px">
            <Row className="mb-20px" justify="space-between" align="middle">
                <Title level={4}>Kullanıcı Listesi</Title>
                <Input
                    placeholder="Ara"
                    prefix={<SearchOutlined style={{ color: '#b8b9bc' }} />}
                    className="d-input d-input-form d-input-filter"
                    style={{ width: 200 }}
                    onChange={handleSearchChange}
                />
            </Row>
            <Table
                scroll={{ x: 768 }}
                loading={loading}
                columns={columns}
                dataSource={users}
                pagination={{
                    current: pagination.current,
                    pageSize: pagination.pageSize,
                    total: pagination.total,
                    showSizeChanger: true,
                }}
                // onChange -> sayfa değişimi, sort, filtreleme
                onChange={handleTableChange}
                rowKey="id"
            />
        </Card>
    );
};