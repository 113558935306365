import { Card, Row, Typography } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import ArrowIcon from '../../assets/icons/arrow-up.svg';
import ProposalService from '../../services/ProposalService';

const { Text, Title } = Typography;

const EarningsPerTransactionCard = () => {
  const [counts, setCounts] = useState<any>()

  const fetchProposals = useCallback(async () => {
      try {
          let response: any = await ProposalService.getAverage();
          setCounts(response.data.toFixed(0));
      } catch (error) {
          console.log(error);
      }
  }, []);

  useEffect(() => {
      fetchProposals()
  }, [])

  return (
    <Link to="">
      <Card bordered={false} className="dashboard-card" bodyStyle={{ padding: 10 }}>
        <div className='dashboard-container'>
          <Row justify={'space-between'}>
            <div className='card-value'>
              {counts??0} ₺
            </div>
            <img src={ArrowIcon} height={20} width={20} />
          </Row>
          <div>
            <Title style={{ fontWeight: 'bold' }} className='mb-0' level={3}>Ortalama Kazanç</Title>
            <Text style={{ fontWeight: 300, fontSize: 18, color: '#001433' }} className="m-0">Sözleşme Başı Son 6 Ay</Text>
          </div>
        </div>
      </Card>
    </Link>
  )
}

export default EarningsPerTransactionCard