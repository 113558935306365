import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import ProposalService from '../../services/ProposalService';

const RenuewChart: React.FC = () => {
    const [series, setSeries] = useState<number[]>([]);
    const [labels, setLabels] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(true);


    // API'den veri çek
    useEffect(() => {
        ProposalService.incomeSharingDistribution()
            .then((response) => {
                const data = response.data;
                const percentages = data.map((item: any) => item.percent); // Yüzdeleri al
                const names = data.map((item: any) => item.offeringName); // Offering adlarını al
                setSeries(percentages);
                setLabels(names);
                setLoading(false);
            })
            .catch((error) => {
                console.error('Gelir dağılımı verisi alınamadı:', error);
            });
    }, []);

    const options: any = {
        chart: {
            type: 'pie',
            height: 350,
            background: '#ffffff',
            toolbar: {
                show: false
            }
        },
        labels: labels, // Offering adları burada kullanılacak
        colors: ['#A9A9A9', '#C1C6B9', '#010101'], // Dilimlerin renkleri
        plotOptions: {
            pie: {
                startAngle: -90,
                endAngle: 90,
                offsetY: 10,
                expandOnClick: false,
                dataLabels: {
                    offset: -10
                }
            }
        },
        fill: {
            type: ['solid', 'pattern', 'pattern'], // Desenler ve düz renk
            pattern: {
                style: ['solid', 'verticalLines', 'circles'], // Desen türleri
                width: 6,
                height: 6,
                strokeWidth: 2,
            },
        },
        dataLabels: {
            enabled: false, // Yüzde gösterimini kapat
        },
        legend: {
            show: true,
            position: 'left',
            horizontalAlign: 'center',
            markers: {
                radius: 0,
            },
            fontSize: '14px',
            labels: {
                colors: ['#001F3F']
            },
        },
        tooltip: {
            enabled: true,
            y: {
                formatter: (val: number) => `${val}%`, // Tooltip'te yüzdeleri göster
            }
        },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: '100%'
                },
                legend: {
                    position: 'bottom'
                }
            }
        }]
    };

    return (
        <div className='renuew-chart'>
            <h3 style={{ color: '#001F3F', margin: '0', fontSize: 24 }}>Gelir Dağılımı</h3>
            {!loading && series.length === 0 ? (
                    <p style={{ color: '#999', fontSize: 18 }}>Henüz bir veriniz bulunmamakta.</p>
                ) : loading ? (
                    <p style={{ color: '#999', fontSize: 18 }}>Veri yükleniyor...</p>
                ) : (
                    <Chart
                        options={options}
                        series={series}
                        type="pie"
                        height={350}
                    />
                )}
        </div>
    );
};

export default RenuewChart;