import React, { useState, useEffect } from 'react';
import { Button, InputNumber, Modal, Table, Popconfirm } from 'antd';
import { ColumnsType } from 'antd/es/table';
import DiscountedOfferingService from '../../services/DiscountedOfferingService';
import TrashIcon from '../../assets/icons/trash.svg';
import PenIcon from '../../assets/icons/pen.svg';
import { SaveOutlined } from '@ant-design/icons';
import { formatToTL, openNotification } from '../../utils';

interface DiscountOfferingModalProps {
    visible: boolean;
    onClose: () => void;
    offeringId: number | null;
}

interface DiscountOffering {
    id: number;
    quantity: number;
    price: number;
}

const DiscountOfferingModal: React.FC<DiscountOfferingModalProps> = ({ visible, onClose, offeringId }) => {
    const [quantity, setQuantity] = useState<number>(0);
    const [price, setPrice] = useState<number>(0);
    const [criteriaList, setCriteriaList] = useState<DiscountOffering[]>([]);
    const [selectedCriteria, setSelectedCriteria] = useState<DiscountOffering | null>(null);

    useEffect(() => {
        if (offeringId !== null) {
            DiscountedOfferingService.getAllByOfferingId(offeringId)
                .then((response) => {
                    setCriteriaList(response.data);
                })
                .catch((error) => {
                    console.error('Kriterler yüklenirken hata:', error);
                });
        }
    }, [offeringId]);

    const handleEdit = (record: DiscountOffering) => {
        setSelectedCriteria(record);
        setQuantity(record.quantity);
        setPrice(record.price);
    };

    const handleSave = () => {
        if (offeringId === null) {
            console.error('Offering ID null olamaz.');
            return;
        }

        const data = { quantity, price, offeringId };

        if (selectedCriteria) {
            // Mevcut kriteri güncelle
            DiscountedOfferingService.update(selectedCriteria.id, data)
                .then(() => {
                    setCriteriaList((prev) =>
                        prev.map((item) =>
                            item.id === selectedCriteria.id
                                ? { ...data, id: selectedCriteria.id }
                                : item
                        )
                    );
                    openNotification('success', 'Başarılı', 'Kriter başarıyla güncellendi.');
                    setSelectedCriteria(null);
                })
                .catch((error) => console.error('Kriter güncellenirken hata:', error));
        } else {
            // Yeni kriter ekle
            DiscountedOfferingService.create(data)
                .then((response) => {
                    setCriteriaList((prev) => [...prev, response.data]);
                    openNotification('success', 'Başarılı', 'Kriter başarıyla eklendi.');
                })
                .catch((error) => console.error('Kriter eklenirken hata:', error));
        }

        setQuantity(0);
        setPrice(0);
    };

    const handleDelete = (id: number) => {
        DiscountedOfferingService.remove(id)
            .then(() => {
                setCriteriaList((prev) => prev.filter((item) => item.id !== id));
                openNotification('success', 'Başarılı', 'Kriter başarıyla silindi.');
            })
            .catch((error) => console.error('Kriter silinirken hata:', error));
    };

    const columns: ColumnsType<DiscountOffering> = [
        {
            title: 'Adet',
            dataIndex: 'quantity',
            key: 'quantity',
            render: (quantity: number) => `${quantity} ve üzeri`,
        },
        {
            title: 'Yeni Fiyat',
            dataIndex: 'price',
            key: 'price',
            render: (_: any, record) => `${formatToTL(record.price)}`,
        },
        {
            title: 'İşlem',
            key: 'action',
            render: (_, record) => (
                <div style={{ display: 'flex', gap: '10px' }}>
                    <Button
                        shape="circle"
                        icon={<img src={PenIcon} alt="pen" className="icon" />}
                        onClick={() => handleEdit(record)}
                        className="icon-button"
                    />
                    <Popconfirm
                        title="Bu kriteri silmek istediğinize emin misiniz?"
                        onConfirm={() => handleDelete(record.id)}
                        okText="Evet"
                        cancelText="Hayır"
                    >
                        <Button
                            shape="circle"
                            icon={<img src={TrashIcon} alt="trash" className="icon" />}
                            className="icon-button"
                        />
                    </Popconfirm>
                </div>
            ),
        },
    ];

    return (
        <Modal
            title="Hesap Kriteri Düzenle"
            visible={visible}
            onCancel={onClose}
            footer={null}
        >
            <div style={{ display: 'flex', gap: '10px', marginBottom: '20px', alignItems: 'center' }}>
                <div>
                    <span>Adet</span>
                    <InputNumber
                        min={0}
                        value={quantity}
                        onChange={(value: number | null) => setQuantity(value ?? 0)}
                        style={{ width: '100%' }}
                    />
                </div>
                <div>
                    <span>Yeni fiyat</span>
                    <InputNumber
                        value={price}
                        onChange={(value: number | null) => setPrice(value ?? 0)}
                        style={{ width: '100%' }}
                    />
                </div>

                <Button
                    key="submit"
                    type="primary"
                    className="d-button dark-button width-auto mt-16px"
                    onClick={handleSave}
                >
                    {selectedCriteria ? 'Güncelle' : 'Kaydet'}
                </Button>
            </div>
            <Table columns={columns} dataSource={criteriaList} rowKey="id" pagination={false} />
        </Modal>
    );
};

export default DiscountOfferingModal;