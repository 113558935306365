import React, { useState, useEffect, useContext, useMemo } from 'react';
import {
  Button,
  Table,
  Input,
  Row,
  Col,
  Card,
  Popconfirm,
  Dropdown,
  Menu,
  Tag,
  Typography,
} from 'antd';
import { SearchOutlined, HolderOutlined } from '@ant-design/icons';
import ParameterModal from './ParameterModal';
import AttributeService from '../../services/AttributeService';
import ToolService from '../../services/ToolService';
import { formatToTL, openNotification } from '../../utils';
import DiscountCriteriaModal from './DiscountCriteriaModal';

import { DndContext, DragOverlay } from '@dnd-kit/core';
import type { DragEndEvent } from '@dnd-kit/core';
import type { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

interface DataType {
  id: string;
  name: string;
  description: string;
  price: number;
  toolId: number | null;
  orderNo: number | null;
  isActive: boolean;
  isGroup?: boolean;
  children?: DataType[];
  key: string;
}

interface RowContextProps {
  setActivatorNodeRef?: (element: HTMLElement | null) => void;
  listeners?: SyntheticListenerMap;
}

const RowContext = React.createContext<RowContextProps>({});

const DragHandle: React.FC = () => {
  const { setActivatorNodeRef, listeners } = useContext(RowContext);
  return (
    <HolderOutlined
      ref={setActivatorNodeRef}
      {...listeners}
      style={{ cursor: 'move', color: '#999' }}
    />
  );
};

const ParameterList: React.FC = () => {
  const [searchText, setSearchText] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedParameter, setSelectedParameter] = useState<any>(null);
  const [data, setData] = useState<DataType[]>([]);
  const [selectedAttributeId, setSelectedAttributeId] = useState<number | null>(
    null
  );
  const [discountModalVisible, setDiscountModalVisible] = useState(false);
  const [tools, setTools] = useState<any[]>([]);
  const [activeId, setActiveId] = useState<string | null>(null);
  const { Title } = Typography;

  const handleOpenDiscountModal = (attributeId: number) => {
    setSelectedAttributeId(attributeId);
    setDiscountModalVisible(true);
  };

  useEffect(() => {
    getAllAttributes();
    getAllTools();
  }, []);

  const getAllAttributes = () => {
    AttributeService.getAll()
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error('Error fetching attributes:', error);
      });
  };

  const getAllTools = () => {
    ToolService.getAllTools()
      .then((response) => {
        setTools(response.data);
      })
      .catch((error) => {
        console.error('Error fetching tools:', error);
      });
  };

  const sortedData = data
    .slice()
    .sort((a, b) => {
      if (a.orderNo == null && b.orderNo == null) {
        return 0;
      } else if (a.orderNo == null) {
        return 1;
      } else if (b.orderNo == null) {
        return -1;
      } else {
        return a.orderNo - b.orderNo;
      }
    });

  const filteredData = sortedData.filter((item) => {
    const searchLower = searchText.toLocaleLowerCase('tr');
    const matchesSearch =
      (item.name?.toLocaleLowerCase('tr').includes(searchLower) || '') ||
      (item.description?.toLocaleLowerCase('tr').includes(searchLower) || '');
    return matchesSearch;
  });

  // Group data by 'toolId' for display purposes
  const groupData = () => {
    const grouped: any[] = [];
    const toolIdMap = new Map();

    filteredData.forEach((item) => {
      item.key = item.id; // Assign unique keys to items

      if (!item.toolId) {
        // Items without a group
        grouped.push({ ...item, isGroup: false });
      } else {
        // Grouped items
        const tool = tools.find((t: any) => t.id === item.toolId);
        const toolName = tool ? tool.name : 'Unknown Component';
        const toolDescription = tool ? tool.description : '';
        let group = toolIdMap.get(item.toolId);

        if (!group) {
          group = {
            key: 'group-' + item.toolId,
            name: toolName,
            description: toolDescription, // Added the tool's description
            isGroup: true,
            children: [],
          };
          toolIdMap.set(item.toolId, group);
          grouped.push(group);
        }

        group.children.push({ ...item, isGroup: false });
      }
    });

    return grouped;
  };

  const treeData = groupData();

  const handleAdd = () => {
    setSelectedParameter(null);
    setModalVisible(true);
  };

  const handleEdit = (record: any) => {
    setSelectedParameter(record);
    setModalVisible(true);
  };

  const handleSave = (data: any, mode: any) => {
    if (mode === 'edit') {
      AttributeService.update(data.id, data)
        .then(() => {
          setData((prevData) =>
            prevData.map((item) => (item.id === data.id ? data : item))
          );
          openNotification('success', 'Başarılı', 'Parametre başarıyla güncellendi.');
          getAllAttributes();
        })
        .catch((error) => console.error('Update error:', error));
    } else {
      AttributeService.create(data)
        .then((response) => {
          setData((prevData) => [...prevData, response.data]);
          openNotification('success', 'Başarılı', 'Parametre başarıyla eklendi.');
          getAllAttributes();
        })
        .catch((error) => console.error('Add error:', error));
    }
    setModalVisible(false);
  };

  const handleDelete = (id: number) => {
    AttributeService.remove(id)
      .then(() => {
        setData((prevData) => prevData.filter((item: any) => item.id !== id));
        openNotification('success', 'Başarılı', 'Parametre başarıyla silindi.');
      })
      .catch((error) => {
        console.error('Delete error:', error);
        openNotification('error', 'Hata', 'Parametre silinemedi.');
      });
  };

  const handleToggleActive = (record: any) => {
    const updatedRecord = { ...record, isActive: !record.isActive };
    AttributeService.update(updatedRecord.id, updatedRecord)
      .then(() => {
        setData((prevData) =>
          prevData.map((item) =>
            item.id === updatedRecord.id ? updatedRecord : item
          )
        );
        openNotification(
          'success',
          'Başarılı',
          `Parametre başarıyla ${!updatedRecord.isActive ? 'aktif' : 'pasif'
          } yapıldı.`
        );
      })
      .catch((error) => {
        console.error('Error toggling active state:', error);
        openNotification('error', 'Hata', 'Parametre güncellenemedi.');
      });
  };

  const menu = (record: any) => (
    <Menu>
      <Menu.Item
        key={`editCriteria-${record.id}`}
        onClick={() => handleOpenDiscountModal(record.id)}
        disabled={!record.toolId}
      >
        Hesap Kriteri Düzenle
      </Menu.Item>
  
      <Menu.Item key={`edit-${record.id}`} onClick={() => handleEdit(record)}>
        Düzenle
      </Menu.Item>
  
      {/* Aktif/Pasif yapma işlemi */}
      <Menu.Item key={`toggleActive-${record.id}`}>
        <Popconfirm
          title={`Bu Parametre'yi ${
            record.isActive ? 'pasif' : 'aktif'
          } yapmak istediğinize emin misiniz?`}
          onConfirm={() => handleToggleActive(record)}
          okText="Evet"
          cancelText="Hayır"
          placement="left" 
          /* placement değiştirmek, menüye çakışma durumunu da azaltır */
        >
          <a>{record.isActive ? 'Pasif Yap' : 'Aktif Yap'}</a>
        </Popconfirm>
      </Menu.Item>
  
      {/* Silme işlemi */}
      <Menu.Item key={`delete-${record.id}`}>
        <Popconfirm
          title="Bu Parametre'yi silmek istediğinize emin misiniz?"
          onConfirm={() => handleDelete(record.id)}
          okText="Evet"
          cancelText="Hayır"
          placement="left"
        >
          <a>Sil</a>
        </Popconfirm>
      </Menu.Item>
    </Menu>
  );

  const columns: any = [
    {
      key: 'sort',
      align: 'center',
      width: 80,
      render: (_: any, record: any) => {
        const indentSize = 70;
        const paddingLeft = !record.toolId ? 0 : indentSize;
        let test = record.isGroup ? 0 : indentSize;;
        return (
          <div style={{
            width: `${test ? 0 : 0}px`,
            paddingLeft: `${test ? paddingLeft : 0}px`,
          }}>
            <DragHandle />
          </div>
        );
      },
    },
    {
      title: 'Adı',
      dataIndex: 'name',
      key: 'name',
      width: 200,

      render: (text: any, record: any) => {
        return (
          <>
            {record.isGroup ? (
              <span style={{ fontWeight: 'bold', fontSize: '16px' }}>{text}</span>
            ) : (
              text
            )}
          </>
        );
      },
    },
    {
      title: 'Açıklama',
      dataIndex: 'description',
      key: 'description',
      render: (text: any, record: any) => {
        if (record.isGroup) {
          return record.description || null; // Display the group's description
        }
        return text;
      },
    },
    {
      title: 'Fiyat',
      dataIndex: 'price',
      key: 'price',
      render: (price: number, record: any) =>
        record.isGroup ? null : `${formatToTL(price)}`,
    },
    {
      title: 'Grubu',
      dataIndex: 'offering',
      key: 'offering',
      width: 170,
      render: (offeringId: number | null, record: any) =>
        record?.offering?.name,
    },
    {
      title: 'Durum',
      dataIndex: 'isActive',
      key: 'isActive',
      render: (isActive: boolean, record: any) =>
        record.isGroup ? null : (
          <Tag color={isActive ? 'green' : 'red'}>
            {isActive ? 'Aktif' : 'Pasif'}
          </Tag>
        ),
    },
    {
      title: 'İşlem',
      key: 'action',
      render: (_: any, record: any) =>
        record.isGroup ? null : (
          <Dropdown overlay={menu(record)} key={record.id} trigger={['click']}>
            <Button className="action-button width-auto" type="default">
              İşlemler
            </Button>
          </Dropdown>
        ),
    },
  ];

  // Flatten the treeData for sorting purposes
  const flattenTreeData = (tree: any[]) => {
    const flatData: any[] = [];
    tree.forEach((item) => {
      flatData.push(item);
      if (item.children && item.children.length > 0) {
        item.children.forEach((child: any) => {
          flatData.push(child);
        });
      }
    });
    return flatData;
  };

  const flattenedData = flattenTreeData(treeData);

  const getParentKey = (itemKey: string) => {
    for (const group of treeData) {
      if (
        group.children &&
        group.children.some((child: any) => child.key === itemKey)
      ) {
        return group.key;
      }
    }
    return null;
  };

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    setActiveId(null);
    if (!over) return;

    if (active.id !== over.id) {
      const activeItem = flattenedData.find((item) => item.key === active.id);
      const overItem = flattenedData.find((item) => item.key === over.id);

      if (activeItem && overItem) {
        const activeParentKey = getParentKey(activeItem.key);
        const overParentKey = getParentKey(overItem.key);

        if (activeParentKey === overParentKey) {
          if (activeParentKey) {
            // Reorder within the same group
            const group = treeData.find((item) => item.key === activeParentKey);
            if (group && group.children) {
              const activeIndex = group.children.findIndex(
                (item: any) => item.key === active.id
              );
              const overIndex = group.children.findIndex(
                (item: any) => item.key === over.id
              );
              if (activeIndex !== -1 && overIndex !== -1) {
                const newChildren = arrayMove(group.children, activeIndex, overIndex);
                group.children = newChildren;

                // Update orderNo values
                const updatedItems: any[] = [];
                let orderNo = 1;
                treeData.forEach((item) => {
                  if (item.isGroup && item.children) {
                    item.children.forEach((child: any) => {
                      child.orderNo = orderNo++;
                      updatedItems.push(child);
                    });
                  } else if (!item.isGroup) {
                    item.orderNo = orderNo++;
                    updatedItems.push(item);
                  }
                });

                // Update data state
                setData((prevData) =>
                  prevData.map((item) => {
                    const updatedItem = updatedItems.find(
                      (uItem) => uItem.id === item.id
                    );
                    return updatedItem
                      ? { ...item, orderNo: updatedItem.orderNo }
                      : item;
                  })
                );

                // Send updated order to server
                saveOrderChanges(updatedItems);
              }
            }
          } else {
            // Reorder standalone items
            const activeIndex = treeData.findIndex((item) => item.key === active.id);
            const overIndex = treeData.findIndex((item) => item.key === over.id);

            if (activeIndex !== -1 && overIndex !== -1) {
              const newTreeData = arrayMove(treeData, activeIndex, overIndex);

              // Update orderNo values
              const updatedItems: any[] = [];
              let orderNo = 1;
              newTreeData.forEach((item) => {
                if (item.isGroup && item.children) {
                  item.children.forEach((child: any) => {
                    child.orderNo = orderNo++;
                    updatedItems.push(child);
                  });
                } else if (!item.isGroup) {
                  item.orderNo = orderNo++;
                  updatedItems.push(item);
                }
              });

              // Update data state
              setData((prevData) =>
                prevData.map((item) => {
                  const updatedItem = updatedItems.find(
                    (uItem) => uItem.id === item.id
                  );
                  return updatedItem
                    ? { ...item, orderNo: updatedItem.orderNo }
                    : item;
                })
              );

              // Send updated order to server
              saveOrderChanges(updatedItems);
            }
          }
        }
      }
    }
  };

  const saveOrderChanges = (updatedItems: DataType[]) => {
    const orderUpdates = updatedItems.map((item) => ({
      id: item.id,
      orderNo: item.orderNo,
    }));

    AttributeService.updateOrder(orderUpdates)
      .then(() => {
        openNotification('success', 'Başarılı', 'Sıralama başarıyla güncellendi.');
        getAllAttributes();
      })
      .catch((error: any) => {
        console.error('Error updating order:', error);
        openNotification('error', 'Hata', 'Sıralama güncellenirken hata oluştu.');
      });
  };

  const RowComponent = (props: any) => {
    const { children, ...restProps } = props;
    const record = children[0]?.props?.record || {};
    const key = record.key || restProps['data-row-key'] || 'no-key';

    const {
      attributes,
      listeners,
      setNodeRef,
      setActivatorNodeRef,
      transform,
      transition,
      isDragging,
    } = useSortable({
      id: key,
    });

    const style: React.CSSProperties = {
      ...restProps.style,
      transform: CSS.Transform.toString(transform),
      transition,
      ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
    };

    const contextValue = useMemo(
      () => ({
        setActivatorNodeRef,
        listeners,
      }),
      [setActivatorNodeRef, listeners]
    );

    return (
      <RowContext.Provider value={contextValue}>
        <tr {...restProps} ref={setNodeRef} style={style} {...attributes}>
          {children}
        </tr>
      </RowContext.Provider>
    );
  };

  const sortableItems = useMemo(() => {
    return flattenedData.map((item) => item.key);
  }, [flattenedData]);

  return (
    <>
      <Card className="br-30px mb-60px">
        <Row justify={'space-between'} align={'middle'} className='mb-16px'>
          <Title style={{ margin: 0 }} level={4}>Parametre Listesi</Title>
          <Row>
            <Input
              placeholder="Ara"
              prefix={<SearchOutlined style={{ color: '#b8b9bc' }} />}
              className="d-input d-input-form mr-16px d-input-filter"
              style={{ width: 200, marginRight: 16 }}
              onChange={(e) => setSearchText(e.target.value)}
            />
            <Button
              onClick={handleAdd}
              type="primary"
              className="d-button width-auto ml-10px dark-button"
            >
              Yeni Parametre Ekle
            </Button>
          </Row>
        </Row>

        <DndContext
          modifiers={[restrictToVerticalAxis]}
          onDragEnd={onDragEnd}
          onDragStart={({ active }: any) => setActiveId(active.id)}
        >
          <SortableContext
            items={sortableItems}
            strategy={verticalListSortingStrategy}
          >
            <Table
              scroll={{ x: 768 }}
              columns={columns}
              dataSource={treeData}
              pagination={false}
              rowKey="key"
              components={{
                body: {
                  row: RowComponent,
                },
              }}
              expandable={{
                defaultExpandAllRows: true,
                expandRowByClick: true,
                rowExpandable: (record) => record.isGroup,
              }}
            />
          </SortableContext>
          <DragOverlay>
            {activeId ? (
              <div
                style={{
                  padding: '8px 16px',
                  background: '#fff',
                  boxShadow: '0 1px 4px rgba(0,0,0,0.1)',
                  borderRadius: '4px',
                }}
              >
                {flattenedData.find((item) => item.key === activeId)?.name}
              </div>
            ) : null}
          </DragOverlay>
        </DndContext>
        <ParameterModal
          visible={modalVisible}
          onClose={() => setModalVisible(false)}
          onSave={handleSave}
          initialData={selectedParameter}
        />
      </Card>
      {selectedAttributeId !== null && (
        <DiscountCriteriaModal
          visible={discountModalVisible}
          onClose={() => setDiscountModalVisible(false)}
          attributeId={selectedAttributeId}
        />
      )}
    </>
  );
};

export default ParameterList;
