import React, { useEffect, useState } from 'react';
import { Button, Tooltip, Avatar, Layout, Typography, Row, Col, Drawer, Grid } from 'antd';
import Sider from 'antd/es/layout/Sider';
import { Link, NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { BellOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import Logo from './../assets/Logo.png';
import DocIcon from './../assets/icons/doc.svg';
import DocIconWhite from './../assets/icons/doc-white.svg';
import HomeIcon from './../assets/icons/home.svg';
import HomeIconWhite from './../assets/icons/home-white.svg';
import ProfileIcon from './../assets/icons/profile.svg';
import ProfileIconWhite from './../assets/icons/profile-white.svg';
import ExitIcon from './../assets/icons/exit.svg';
import ExitIconWhite from './../assets/icons/exit-white.svg';
import PaperIcon from './../assets/icons/paper.svg';
import NotifyIcon from './../assets/icons/notify.svg';
import PaperIconWhite from './../assets/icons/paper-white.svg';
import ValletIcon from './../assets/icons/vallet.svg';
import ValletIconWhite from './../assets/icons/vallet-white.svg';
import SettingsIcon from './../assets/icons/settings.svg';
import SettingsIconWhite from './../assets/icons/setting-white.svg';
import ConfirmIcon from './../assets/confirm.png';
import ConfirmIconGreen from './../assets/confirm-green.png';
import DocConfirm from './../assets/doc-confirm.svg';
import DocConfirmWhite from './../assets/doc-confirm-white.svg';

import {
    MessageOutlined,
    EditOutlined,
    InfoCircleOutlined
} from '@ant-design/icons'
import './layouts.scss';
import ImageService from '../services/ImageService';
import axios from 'axios';


const { Header, Content } = Layout;
const { Title, Text } = Typography;
const { useBreakpoint } = Grid;

const MainLayout = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [loaded, setLoaded] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [profilePhoto, setProfilePhoto] = useState('');
    const [roles, setRoles] = useState<any>();
    const [profileCompletion, setProfileCompletion] = useState(localStorage.getItem('isPartner'));


    const [drawerVisible, setDrawerVisible] = useState(false);
    let imageId = localStorage.getItem('profilePhoto');

    const screens = useBreakpoint();
    const isMobile = !screens.md;

    const routeTitles = {
        '/': 'Ana Sayfa',
        '/profile': 'Hesap Bilgileri',
        '/companies': 'Firmalar',
        '/proposals': 'Teklif Yönetimi',
        '/users': 'Kullanıcılar',
        '/vallet': 'Cüzdan',
        '/roles': 'Ayarlar',
        '/notes': 'Notlar',
        '/tickets': 'Destek',
        '/all-tickets': 'Destek',
        '/statuses': 'Durumlar',
        '/settings': 'Ayarlar',
        '/project-followup': 'İş Yönetimi',
        '/proposals/create': 'Teklif Oluştur',
    };

    const getPageTitle = (pathname: string) => {
        const companyRegex = /\/companies\/\d+$/;
        const userRegex = /\/users\/\d+$/;

        if (pathname === '/' && roles && roles.some((role: any) => role.name === 'CUSTOMER')) {
            return (
                <span>
                    <b style={{ fontWeight: '700' }}>Hoşgeldin {firstName}!</b> <span style={{ color: '#4A576E', fontWeight: 500 }}> Gelirini artırmak için <br /> hemen teklif oluştur ve fırsatları değerlendir!</span>
                </span>
            );
        }
        if (companyRegex.test(pathname)) {
            return 'Firma Detayı';
        } else if (userRegex.test(pathname)) {
            return 'Kullanıcı Detayı';
        } else {
            return routeTitles[pathname as keyof typeof routeTitles] || 'Sayfa Bulunamadı';
        }
    };

    const pageTitle = getPageTitle(location.pathname);

    useEffect(() => {
        const accessToken = localStorage.getItem('accessToken');
        let storedFirstName = localStorage.getItem('firstName');
        let storedLastName = localStorage.getItem('lastName');
        let storedRoles: any = localStorage.getItem('roles');
        let parsedRoles = JSON.parse(storedRoles);
        setRoles(parsedRoles);

        let profileCompletion = localStorage.getItem('isPartner');
        setFirstName(storedFirstName ?? '');
        setLastName(storedLastName ?? '');
        setProfileCompletion(profileCompletion);

        if (!accessToken || accessToken === 'null' || accessToken === null) {
            handleTokenExpiration();
        } else {
            setLoaded(true);
        }
    }, [location.pathname]);


    const handleTokenExpiration = async () => {
        console.log('Token süresi dolmuş, yenileniyor...');
        const refreshToken = localStorage.getItem('refreshToken');
        console.log('refreshToken', refreshToken);

        if (!refreshToken) {
            logout();
            return;
        }

        try {
            const apiUrl = process.env.REACT_APP_API_BASE_URL + '/auth/refresh';

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: apiUrl,
                headers: {
                    'Content-Type': 'application/json', // Başlık ekleniyor
                },
                data: JSON.stringify({ refreshToken }), // JSON formatında gönderim
            };
            axios.request(config)
                .then((response: any) => {
                    console.log(response);
                    localStorage.setItem('accessToken', response?.data?.accessToken);
                    localStorage.setItem('accessTokenExpiredAt', response?.data?.accessTokenExpiredAt);
                    localStorage.setItem('refreshToken', response?.data?.refreshToken);
                    localStorage.setItem('refreshTokenExpiredAt', response?.data?.refreshTokenExpiredAt);
                })

        } catch (error) {
            console.error('Token yenileme başarısız:', error);
            logout();
        }
    };

    const logout = () => {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('accessTokenExpiredAt');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('refreshTokenExpiredAt');
        localStorage.removeItem('firstName');
        localStorage.removeItem('lastName');
        localStorage.removeItem('email');
        localStorage.removeItem('phoneNumber');
        localStorage.removeItem('id');
        localStorage.removeItem('roles');
        localStorage.removeItem('permissions');
        localStorage.removeItem('profilePhotoBase64');
        localStorage.removeItem('profilePhoto');

        navigate('/login');
    };

    const toggleDrawer = () => {
        setDrawerVisible(!drawerVisible);
    };

    const fetchImage = async () => {
        try {
            const imageResponse: any = await ImageService.getImage(imageId);
            const imageUrl = imageResponse?.data?.preSignUrl;
            localStorage.setItem('profilePhotoBase64', imageUrl);
            setProfilePhoto(imageUrl);


        } catch (error) {
            console.error("Resim yüklenirken hata oluştu:", error);
        }
    };

    useEffect(() => {
        setTimeout(() => {
            const cachedImage = localStorage.getItem('profilePhotoBase64');
            if (cachedImage) {
                setProfilePhoto(cachedImage ?? '');
            } else {
                console.warn('Geçersiz imageId:', localStorage.getItem('profilePhoto'));
            }
            if (imageId) {
                fetchImage();
            }

        }, 250);


    }, [imageId])


    // Menu öğelerini bir fonksiyona taşıyoruz
    // Değişiklik: Yapı ve sınıflar güncellendi
    const renderMenuItems = (isMobileMenu = false) => (
        <div className={`menu-item-container ${isMobileMenu ? 'mobile-menu' : ''}`}>
            {/* Menü öğeleri */}
            <NavLink to="/" className="menu-link" onClick={() => isMobileMenu && setDrawerVisible(false)}>
                {({ isActive }) => (
                    <div className="menu-item-wrapper">
                        <div className={`menu-item ${isActive ? 'active' : ''}`}>
                            <img src={isActive ? HomeIconWhite : HomeIcon} className='icon' alt="Home" />
                        </div>
                        {isMobileMenu && <span className='menu-title'>Ana Sayfa</span>}
                    </div>
                )}
            </NavLink>
            {roles && roles.some((role: any) => role.name === 'ADMIN') && (
                <NavLink to="/users" className="menu-link" onClick={() => isMobileMenu && setDrawerVisible(false)}>
                    {({ isActive }) => (
                        <div className="menu-item-wrapper">
                            <div className={`menu-item ${isActive ? 'active' : ''}`}>
                                {!isMobileMenu ?
                                    <Tooltip title="Kullanıcılar" placement="right">
                                        <img src={isActive ? ProfileIconWhite : ProfileIcon} className='icon' alt="Users" />
                                    </Tooltip> : <img src={isActive ? ProfileIconWhite : ProfileIcon} className='icon' alt="Users" />
                                }
                            </div>
                            {isMobileMenu && <span className='menu-title'>Kullanıcılar</span>}
                        </div>
                    )}
                </NavLink>
            )}
            <NavLink to="/companies" className="menu-link" onClick={() => isMobileMenu && setDrawerVisible(false)}>
                {({ isActive }) => (
                    <div className="menu-item-wrapper">
                        <div className={`menu-item ${isActive ? 'active' : ''}`}>
                            {!isMobileMenu ?
                                <Tooltip title="Firmalar" placement="right">
                                    <img src={isActive ? DocIconWhite : DocIcon} className='icon' alt="Firmalar" />
                                </Tooltip> : <img src={isActive ? DocIconWhite : DocIcon} className='icon' alt="Firmalar" />
                            }
                        </div>
                        {isMobileMenu && <span className='menu-title'>Firmalar</span>}
                    </div>
                )}
            </NavLink>
            <NavLink to="/proposals" className="menu-link" onClick={() => isMobileMenu && setDrawerVisible(false)}>
                {({ isActive }) => (
                    <div className="menu-item-wrapper">
                        <div className={`menu-item ${isActive ? 'active' : ''}`}>
                            {!isMobileMenu ?
                                <Tooltip title="Teklifler" placement="right">
                                    <img src={isActive ? PaperIconWhite : PaperIcon} className='icon' alt="Teklifler" />
                                </Tooltip> : <img src={isActive ? PaperIconWhite : PaperIcon} className='icon' alt="Teklifler" />
                            }
                        </div>
                        {isMobileMenu && <span className='menu-title'>Teklifler</span>}
                    </div>
                )}
            </NavLink>
            {/* {roles && roles.some((role: any) => role.name === 'ADMIN') && ( */}
            <NavLink to="/project-followup" className="menu-link" onClick={() => isMobileMenu && setDrawerVisible(false)}>
                {({ isActive }) => (
                    <div className="menu-item-wrapper">
                        <div className={`menu-item ${isActive ? 'active' : ''}`}>
                            {!isMobileMenu ?
                                <Tooltip title="İş Takip" placement="right">
                                    <img src={isActive ? DocConfirmWhite : DocConfirm} className='icon' alt="İş Takip" />
                                </Tooltip> : <img src={isActive ? DocConfirmWhite : DocConfirm} className='icon' alt="İş Takip" />
                            }
                        </div>
                        {isMobileMenu && <span className='menu-title'>İş Takip</span>}
                    </div>
                )}
            </NavLink>
            {/* )} */}
            {roles && !roles.some((role: any) => role.name === 'ADMIN') && (
                <NavLink to="/vallet" className="menu-link" onClick={() => isMobileMenu && setDrawerVisible(false)}>
                    {({ isActive }) => (
                        <div className="menu-item-wrapper">
                            <div className={`menu-item ${isActive ? 'active' : ''}`}>
                                {!isMobileMenu ?
                                    <Tooltip title="Cüzdan" placement="right">
                                        <img src={isActive ? ValletIconWhite : ValletIcon} className='icon' alt="Cüzdan" />
                                    </Tooltip> : <img src={isActive ? ValletIconWhite : ValletIcon} className='icon' alt="Cüzdan" />
                                }
                            </div>
                            {isMobileMenu && <span className='menu-title'>Cüzdan</span>}
                        </div>
                    )}
                </NavLink>
            )}
            <NavLink to={roles && roles.some((role: any) => role.name === 'ADMIN') ? `/all-tickets` : '/tickets'} className="menu-link" onClick={() => isMobileMenu && setDrawerVisible(false)}>
                {({ isActive }) => (
                    <div className="menu-item-wrapper">
                        <div className={`menu-item ${isActive ? 'active' : ''}`}>
                            {!isMobileMenu ?
                                <Tooltip title="Destek" placement="right">
                                    {isActive ? <MessageOutlined className='icon' style={{ color: "white" }} /> : <MessageOutlined className='icon' />}
                                </Tooltip> : <>
                                    {isActive ? <MessageOutlined className='icon' style={{ color: "white" }} /> : <MessageOutlined className='icon' />}</>
                            }
                        </div>
                        {isMobileMenu && <span className='menu-title'>Destek</span>}
                    </div>
                )}
            </NavLink>
        </div>
    );

    // Alt menü öğeleri (Ayarlar ve Çıkış)
    const renderFooterMenuItems = (isMobileMenu = false) => (
        <div className={`py-20px ${isMobileMenu ? 'mobile-menu' : ''}`}>
            {roles && roles.some((role: any) => role.name === 'ADMIN') && (
                <NavLink to="/settings" className="menu-link" onClick={() => isMobileMenu && setDrawerVisible(false)}>
                    {({ isActive }) => (
                        <div className="menu-item-wrapper">
                            <div className={`menu-item ${isActive ? 'active' : ''}`}>
                                {!isMobileMenu ?
                                    <Tooltip title="Ayarlar" placement="right">
                                        <img src={isActive ? SettingsIconWhite : SettingsIcon} className='icon' alt="Settings" />
                                    </Tooltip> : <img src={isActive ? SettingsIconWhite : SettingsIcon} className='icon' alt="Settings" />
                                }
                            </div>
                            {isMobileMenu && <span className='menu-title'>Ayarlar</span>}
                        </div>
                    )}
                </NavLink>
            )}
            <div className="menu-link" onClick={() => { logout(); isMobileMenu && setDrawerVisible(false); }}>
                <div className="menu-item-wrapper">
                    <div className="menu-item">
                        <img src={ExitIcon} className='icon' alt="Exit" />
                    </div>
                    {isMobileMenu && <span className='menu-title'>Çıkış</span>}
                </div>
            </div>
        </div>
    );

    return (
        <>
            {loaded ? (
                <Layout className='height-100vh '>
                    {!isMobile && (
                        <Sider
                            width={80}
                            className='layout-sider'
                        >
                            <div className='py-20px'>
                                <Link to="/" className='d-flex justify-content-center mb-20px'>
                                    <img src={Logo} alt='logo' width={40} className='mt-16px' />
                                </Link>
                            </div>
                            {renderMenuItems()}
                            {/* Alt kısımdaki menü öğeleri */}
                            {renderFooterMenuItems()}
                        </Sider>
                    )}
                    <Layout>
                        <Content
                            className='layout-content'
                        >
                            <Header
                                className='d-flex justify-content-between align-items-center p-20px layout-header mb-16px'>
                                <div className='d-flex align-items-center '>
                                    {isMobile ? (
                                        <div className='d-flex align-items-center'>
                                            <Button type='text' onClick={toggleDrawer} style={{ padding: 0 }}>
                                                <MenuUnfoldOutlined style={{ fontSize: '24px', fontWeight: 'bold' }} />
                                            </Button>
                                            {/* Sayfa adını ikonun yanında göster */}
                                            <Title level={4} style={{ margin: 0, marginLeft: 8 }}>
                                                {pageTitle}
                                            </Title>
                                        </div>
                                    ) : (
                                        <Title level={3} style={{ margin: 0 }}>
                                            {pageTitle}
                                        </Title>
                                    )}
                                </div>
                                <div className='d-flex align-items-center gap-16px'>
                                    <Tooltip title="Bildirimler">
                                        <Row justify={'center'} align={'middle'} className='notify-box'>
                                            <img src={NotifyIcon} alt="Bildirim" className='icon' />
                                        </Row>
                                    </Tooltip>
                                    <Row gutter={8} align={'middle'} justify={'center'} style={{ backgroundColor: '#fff', borderRadius: 24, lineHeight: 4, cursor: 'pointer', minWidth: 80, height: 48 }} onClick={() => navigate('/profile')}>
                                        <Col>
                                            <div style={{ height: 30, display: 'flex', alignItems: 'center', alignContent: 'center' }}>
                                                <Avatar size={40} src={profilePhoto ?? ''} style={{ border: '1px solid #d9d9d9', cursor: 'pointer' }}>
                                                    {firstName[0]?.toUpperCase()}</Avatar>
                                                <span className="hide-on-mobile" style={{ marginLeft: 5 }}>{firstName ?? ''} {lastName ?? ''}</span>
                                            </div>
                                        </Col>
                                        <Col>

                                        </Col>
                                        <Col>
                                            <img src={profileCompletion ? ConfirmIconGreen : ConfirmIcon} alt="" style={{ display: 'flex', alignContent: 'center', marginRight: 10 }} />
                                        </Col>
                                    </Row>
                                </div>
                            </Header>
                            <div className='layout-content-container' style={{ height: '100vh', overflowY: 'auto' }}>
                                <Outlet />
                            </div>
                        </Content>
                    </Layout>
                    {/* Drawer menü */}
                    {isMobile && (
                        <Drawer
                            title="Menü"
                            placement="left"
                            onClose={toggleDrawer}
                            open={drawerVisible}
                        >
                            {renderMenuItems(true)}
                            {renderFooterMenuItems(true)}
                        </Drawer>
                    )}
                </Layout>
            ) : null}
        </>
    );
};

export default MainLayout;