import React, { useEffect, useState } from 'react';
import { Modal, Form, Input, Upload, Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import DocumentService from '../../services/DocumentService';
import ProposalService from '../../services/ProposalService';
import { openNotification } from '../../utils';

interface PaymentModalProps {
  visible: boolean;
  onClose: () => void;
  proposalId: number | null;
  mode: 'view' | 'edit'; // Görüntüleme ve düzenleme modları
}

const PaymentModal: React.FC<PaymentModalProps> = ({ visible, onClose, proposalId, mode }) => {
  const [form] = Form.useForm();
  const [uploadedDocumentUrl, setUploadedDocumentUrl] = useState<string | null>(null);
  const [proposal, setProposal] = useState<any>({});
  useEffect(() => {
    const fetchProposalData = async () => {
      if (proposalId) {
        try {
          const response = await ProposalService.get(proposalId);
          const { paidAmount, paidAmountDescription, paidDocumentId } = response.data;
          setProposal(response.data);
          // Eğer belge yüklü ise URL'yi al
          if (paidDocumentId) {
            const documentResponse = await DocumentService.getDocument(paidDocumentId);
            setUploadedDocumentUrl(documentResponse.data.preSignUrl);
          } else {
            setUploadedDocumentUrl(null);
          }

          // Formu doldur
          form.setFieldsValue({
            paidAmount: paidAmount ?? response.data.incomeSharePrice,
            paidAmountDescription,
          });
        } catch (error) {
          console.error('Teklif verileri alınamadı:', error);
          openNotification('error', 'Hata', 'Teklif bilgileri alınamadı.');
        }
      }
    };

    if (visible) {
      fetchProposalData();
    } else {
      form.resetFields();
      setUploadedDocumentUrl(null);
    }
  }, [visible, proposalId]);

  const onSubmit = async (values: any) => {
    try {
      let paidDocumentId = null;

      // Eğer dosya seçilmişse, önce dosyayı yükle
      if (values.document) {
        const uploadResponse = await DocumentService.postDocument(values.document.file, 'payment_receipt');
        paidDocumentId = uploadResponse.data.id;
      }

      // Proposal güncelleme payload
      const payload = {
        paidAmount: proposal.maxDiscountPrice || values.paidAmount,
        paidAmountDescription: values.paidAmountDescription,
        paidDocumentId,
      };

      await ProposalService.update(proposalId, payload);
      openNotification('success', 'Başarılı', 'Ödeme bilgisi kaydedildi.');
      onClose();
    } catch (error) {
      console.error('Hata oluştu:', error);
      openNotification('error', 'Hata', 'Ödeme bilgisi kaydedilemedi.');
    }
  };

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      onOk={() => form.submit()} // Düzenleme modunda formu gönder
      title={mode === 'edit' ? 'Ödeme Yap' : 'Ödeme Bilgisi Görüntüle'}
      footer={mode === 'view' ? null : undefined} // Görüntüleme modunda footer gizle
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onSubmit}
        disabled={mode === 'view'} // Görüntüleme modunda formu pasif yap
      >
        <Form.Item label="Açıklama" name="paidAmountDescription">
          <Input.TextArea
            placeholder={mode === 'edit' ? 'Ödeme açıklaması girin' : ''}
            disabled={mode === 'view'} // Görüntüleme modunda pasif
          />
        </Form.Item>

        <Form.Item
          label="Ödeme Tutarı"
          name="paidAmount"
          rules={[
            // { required: true, message: 'Lütfen bir tutar girin.' },
            // {
            //   pattern: /^[0-9]+$/,
            //   message: 'Lütfen sadece rakam girin.',
            // },
          ]}
        >
          <Input
            placeholder="Ödenen tutarı girin"
            type="number"
            value={proposal.incomeSharePrice}
            disabled // Görüntüleme modunda pasif
          />
        </Form.Item>

        {/* {uploadedDocumentUrl ? (
          <Form.Item label="Yüklü Dekont">
            <a href={uploadedDocumentUrl} target="_blank" rel="noopener noreferrer">
              Mevcut Dekontu Görüntüle
            </a>
          </Form.Item>
        ) : null} */}

        {/* {mode === 'edit' && (
          <Form.Item
            label="Dekont Ekle"
            name="document"
            valuePropName="file"
            rules={!uploadedDocumentUrl ? [{ required: true, message: 'Lütfen bir dosya yükleyin.' }] : undefined}
          >
            <Upload
              name="document"
              beforeUpload={() => false}
              accept=".pdf,.png,.jpg,.jpeg"
            >
              <Button icon={<UploadOutlined />}>Dekont Yükle</Button>
            </Upload>
          </Form.Item>
        )} */}
      </Form>
    </Modal>
  );
};

export default PaymentModal;