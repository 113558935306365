import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import ProposalService from '../../services/ProposalService';
import { ApexOptions } from 'apexcharts';

const ProposalChart: React.FC = () => {
  const [approvedData, setApprovedData] = useState<{ x: number; y: number }[]>([]);
  const [rejectedData, setRejectedData] = useState<{ x: number; y: number }[]>([]);
  const [uniqueMonths, setUniqueMonths] = useState<string[]>([]);

  const fetchProposals = async () => {
    try {
      const response: any = await ProposalService.monthlyCounts();

      if (response && response.data) {
        const approved = response.data.approved;
        const rejected = response.data.rejected;

        // Collect unique months from both approved and rejected
        const monthsSet = new Set<string>();
        approved.forEach((item: any) => monthsSet.add(item.month));
        rejected.forEach((item: any) => monthsSet.add(item.month));

        // Convert to an array and (optionally) sort them if needed
        const monthsArray = Array.from(monthsSet);

        // Map each month to a numeric index
        const monthToIndex: { [key: string]: number } = {};
        monthsArray.forEach((m, i) => {
          monthToIndex[m] = i;
        });

        // Filter out duplicates
        const uniqueApproved = approved.reduce((acc: any[], curr: any) => {
          if (!acc.find((item) => item.month === curr.month)) {
            acc.push(curr);
          }
          return acc;
        }, []);

        const uniqueRejected = rejected.reduce((acc: any[], curr: any) => {
          if (!acc.find((item) => item.month === curr.month)) {
            acc.push(curr);
          }
          return acc;
        }, []);

        // Create series data with x as numeric index
        const approvedSeriesData = uniqueApproved.map((item: any) => ({
          x: monthToIndex[item.month],
          y: Number(item.count),
        }));

        const rejectedSeriesData = uniqueRejected.map((item: any) => ({
          x: monthToIndex[item.month],
          y: Number(item.count),
        }));

        setUniqueMonths(monthsArray);
        setApprovedData(approvedSeriesData);
        setRejectedData(rejectedSeriesData);
      } else {
        console.error('API response is missing or invalid:', response);
      }
    } catch (error) {
      console.error('API error:', error);
    }
  };

  useEffect(() => {
    fetchProposals();
  }, []);

  const options: ApexOptions = {
    chart: {
      type: 'line',
      height: 350,
      background: '#ffffff',
      toolbar: { show: false },
      zoom: { enabled: false },
      animations: { enabled: true },
    },
    colors: ['#000', '#D1D1D1'],
    stroke: {
      curve: 'smooth',
      width: [3, 3],
      dashArray: [0, 5],
    },
    xaxis: {
      type: 'numeric',
      min: 0, 
      tickAmount: uniqueMonths.length > 1 ? uniqueMonths.length - 1 : 0,
      labels: {
        style: { colors: '#6c757d' },
        formatter: (val: any) => {
          // Convert numeric index back to month name
          return uniqueMonths[val] !== undefined ? uniqueMonths[val] : '';
        }
      },
      // Ensure the first data point is at x=0 (y-axis), no extra offset needed
    },
    yaxis: {
      labels: {
        formatter: (val: number) => val.toString(),
        style: { colors: '#6c757d' },
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
      x: {
        formatter: (val: number) => {
          // Show month name in tooltip
          return uniqueMonths[val] !== undefined ? uniqueMonths[val] : '';
        },
      },
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
      itemMargin: { horizontal: 10, vertical: 5 },
    },
  };

  const series = [
    { name: 'Onaylanan', data: approvedData },
    { name: 'Olumsuz', data: rejectedData },
  ];

  return (
    <div>
      <h3 style={{ color: '#001F3F', fontSize: 24 }}>Teklifler</h3>
      {series[0].data.length > 0 || series[1].data.length > 0 ? (
        <Chart options={options} series={series} type="line" height={410} />
      ) : (
        <p>Veriler yükleniyor...</p>
      )}
    </div>
  );
};

export default ProposalChart;