import React, { useState, useEffect } from "react";
import {
    Button,
    Table,
    Modal,
    Form,
    Input,
    Row,
    Typography,
    Card,
    InputNumber,
    Dropdown,
    Menu,
    Popconfirm,
} from "antd";
import OfferingService from "../../services/OfferingService";
import DiscountCriteriaModal from "./DiscountCriteriaModal";
import { SearchOutlined } from "@ant-design/icons";
import { openNotification, formatToTL } from "../../utils";

const { Title } = Typography;

const OfferingList: React.FC = () => {
    const [offerings, setOfferings] = useState<any[]>([]);
    const [filteredOfferings, setFilteredOfferings] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [editingOffering, setEditingOffering] = useState<any | null>(null);
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [isDiscountModalVisible, setIsDiscountModalVisible] = useState(false);
    const [selectedOfferingId, setSelectedOfferingId] = useState<number | null>(null);
    const [form] = Form.useForm();

    useEffect(() => {
        loadOfferings();
    }, []);

    useEffect(() => {
        const filtered = offerings.filter((offering) =>
            offering.name?.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredOfferings(filtered);
    }, [searchTerm, offerings]);

    const loadOfferings = async () => {
        setLoading(true);
        try {
            const response = await OfferingService.getAllOfferings();
            setOfferings(response.data);
            setFilteredOfferings(response.data);
        } catch (error) {
            openNotification("error", "Hata", "Hizmetler yüklenirken bir hata oluştu.");
        } finally {
            setLoading(false);
        }
    };

    const handleCreate = () => {
        setEditingOffering(null);
        form.resetFields();
        setIsModalVisible(true);
    };

    const handleEdit = (offering: any) => {
        setEditingOffering(offering);
        form.setFieldsValue(offering);
        setIsModalVisible(true);
    };

    const handleDelete = async (id: number) => {
        try {
            await OfferingService.deleteOffering(id);
            openNotification("success", "Başarılı", "Hizmet başarıyla silindi.");
            loadOfferings();
        } catch (error) {
            openNotification("error", "Hata", "Hizmet silinemedi.");
        }
    };

    const handleSubmit = async (values: any) => {
        try {
            if (editingOffering) {
                await OfferingService.updateOffering(editingOffering.id, values);
                openNotification("success", "Başarılı", "Hizmet güncellendi.");
            } else {
                await OfferingService.createOffering(values);
                openNotification("success", "Başarılı", "Hizmet oluşturuldu.");
            }
            loadOfferings();
            setIsModalVisible(false);
        } catch (error) {
            openNotification("error", "Başarısız", "İşlemde hata meydana geldi.");
        }
    };

    const handleOpenDiscountModal = (offeringId: number) => {
        setSelectedOfferingId(offeringId);
        setIsDiscountModalVisible(true);
    };

    const menu = (record: any) => (
        <Menu>
            <Menu.Item
                key={`discount-${record.id}`}
                onClick={() => handleOpenDiscountModal(record.id)}
            >
                Hesap Kriteri Düzenle
            </Menu.Item>
            <Menu.Item key={`edit-${record.id}`} onClick={() => handleEdit(record)}>
                Düzenle
            </Menu.Item>
            <Popconfirm
                title="Bu hizmeti silmek istediğinize emin misiniz?"
                onConfirm={() => handleDelete(record.id)}
                okText="Evet"
                cancelText="Hayır"
            >
                <Menu.Item key={`delete-${record.id}`}>Sil</Menu.Item>
            </Popconfirm>
        </Menu>
    );

    const columns = [
        {
            title: "Adı",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Fiyat",
            dataIndex: "price",
            key: "price",
            render: (price: number) => `${formatToTL(price)}`,
        },
        {
            title: "İşlemler",
            key: "actions",
            render: (_: any, record: any) => (
                <Dropdown overlay={menu(record)} trigger={["click"]}>
                    <Button className="action-button width-auto" type="default">
                        İşlemler</Button>
                </Dropdown>
            ),
        },
    ];

    return (
        <Card className="br-30px">
            <Row justify="space-between" align="middle" style={{ marginBottom: 16 }}>
                <Title style={{ margin: 0 }} level={4}>
                    Hizmet Grubu Listesi
                </Title>
                <Row>
                    <Input
                        placeholder="Ara"
                        prefix={<SearchOutlined style={{ color: "#b8b9bc" }} />}
                        style={{ width: 200, marginRight: 16 }}
                        className="d-input-filter d-input"
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <Button
                        type="primary"
                        className="d-button dark-button width-auto"
                        onClick={handleCreate}
                    >
                        Yeni Hizmet Ekle
                    </Button>
                </Row>
            </Row>
            <Table
                dataSource={filteredOfferings}
                columns={columns}
                rowKey="id"
                loading={loading}
            />
            <Modal
                title={editingOffering ? "Düzenle" : "Yeni Hizmet Ekle"}
                visible={isModalVisible}
                footer={null}
                onCancel={() => setIsModalVisible(false)}
            >
                <Form form={form} onFinish={handleSubmit} layout="vertical">
                    <Form.Item
                        label="Adı"
                        name="name"
                        rules={[{ required: true, message: "Lütfen hizmet adı giriniz." }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Fiyat"
                        name="price"
                        rules={[{ required: true, message: "Lütfen fiyat giriniz." }]}
                    >
                        <Input
                            onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }}
                        />
                    </Form.Item>
                    <Row justify="center">
                        <Button
                            type="primary"
                            htmlType="submit"
                            className="d-button dark-button width-auto"
                        >
                            {editingOffering ? "Güncelle" : "Kaydet"}
                        </Button>
                    </Row>
                </Form>
            </Modal>
            {selectedOfferingId !== null && (
                <DiscountCriteriaModal
                    visible={isDiscountModalVisible}
                    onClose={() => setIsDiscountModalVisible(false)}
                    offeringId={selectedOfferingId}
                />
            )}
        </Card>
    );
};

export default OfferingList;