import { http } from "../http-common";
import { IUserRequest, IUserResponse } from "../types/User";


const getMe = () => {
    return http.get<IUserResponse>(`/customers/me`);
};

const getMeUser = () => {
    return http.get<IUserResponse>(`/users/me`);
};

const get = (id: any) => {
    return http.get<IUserResponse>(`/customers/${id}`);
};

const getAccountMe = () => {
    return http.get<IUserResponse>(`/accounts/me`);
};

const toggleStatus = (id: number, newStatus: boolean) => {
    return http.patch(`/customers/${id}/status`, { status: newStatus });
}

const getAll = (params: { name?: string; page: any; size: any, sortDirection: any, sortField: any }) => {
    return http.get<any[]>('/customers', { params });
};

const getAllWithoutPagination = (search?: string) => {
    const params = search ? { params: { search } } : {};
    return http.get<any[]>('/customers', params);
};

const updateMe = (data: any) => {
    return http.patch<IUserRequest>(`/customers/me`, data);
};

const updateMeUser = (data: any) => {
    return http.patch<IUserRequest>(`/users/me`, data);
};

const update = (id: any, data: any) => {
    return http.patch<IUserRequest>(`/customers/${id}`, data);
};

const updatePassword = (data: any) => {
    return http.put<any>(`/accounts/me/password`, data);
};

const updateEmailRequest = (data: any) => {
    return http.post<any>(`/accounts/me/email-update-request`, data);
};

const getAttemp = (params: { page: number; size: number; email?: string }) => {
    return http.get<IUserResponse>(`/login-attempts`, {
        params: {
            page: params.page,
            size: params.size,
            email: params.email,
        },
    });
};

const UserService = {
    getMe,
    getMeUser,
    getAccountMe,
    updateMe,
    updateMeUser,
    updatePassword,
    getAll,
    updateEmailRequest,
    getAttemp,
    toggleStatus,
    get,
    update,
    getAllWithoutPagination
};

export default UserService;