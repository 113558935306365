import React from 'react';
import { Button, Col, Form, Row, Typography, Layout, Input } from 'antd';
import { EyeTwoTone, EyeInvisibleOutlined } from '@ant-design/icons';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { openNotification } from '../../utils';
import './auth.scss';
import successIcon from './../../assets/auth/success-icon.png';
import Logo from '../../assets/auth/kontrolxlogo.svg';

const { Title, Paragraph } = Typography;
const { Content } = Layout;

const ForgotToNewPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [email, setEmail] = useState<string>('');
  const [otp, setOtp] = useState<string>('');
  const [isPasswordChanged, setIsPasswordChanged] = useState<boolean>(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const emailParam = queryParams.get('email');
    if (emailParam) {
      setEmail(emailParam);
    }
  }, [location.search]);

  const onFinishOtpAndNewPassword = (values: { newPassword: string; confirmPassword: string }) => {
    let userData = {
      otp: otp,
      newPassword: values.newPassword,
      email: email,
    };

    const apiUrl = process.env.REACT_APP_API_BASE_URL + '/auth/reset-password';

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: apiUrl,
      data: userData,
    };

    axios
      .request(config)
      .then((response: any) => {
        console.log(response);
        openNotification('success', 'Başarılı', 'Şifreniz başarıyla yenilendi. Giriş yapabilirsiniz.');
        setIsPasswordChanged(true);
        setTimeout(() => {
          navigate('/login');
        }, 5000);
      })
      .catch((e: any) => {
        console.log(e);
        if (e.response && e.response.status === 400 && e.response.data.message === 'Validation failed') {
          const validationErrors = e.response.data.validationError;
          if (validationErrors.newPassword) {
            openNotification('error', 'Başarısız', `Yeni şifreniz büyük harf, küçük harf, ve rakam içermelidir ve en az 8 karakter olmalıdır!`);
          } else {
            openNotification('error', 'Başarısız', 'Tek Kullanımlık Parolanız hatalı veya şifreniz güçsüz! Kontrol ederek tekrar deneyin.');
          }
        } else {
          openNotification('error', 'Başarısız', 'Girilen Tek Kullanımlık Parola Hatalı! Kontrol ederek tekrar deneyin.');
        }
      });
  };

  return (
    <Content>
      {isPasswordChanged ? (
        <>
          <Row justify={"center"}>
            <img src={Logo} alt="Kontrol X" className='logo' height={40} style={{ margin: '20px 0 10px 0' }} />
          </Row>
          <Row justify={'center'} className='mb-4 mt-4'>
            <img src={successIcon} alt="Success" width={64} />
          </Row>
          <Row align={'middle'} justify={'center'} className='text-center'>
            <Title level={2}>Şifreniz başarıyla güncellenmiştir.</Title>
            <Paragraph className='fs-20 fw-400 font-color-subtext'>
              Yeni şifrenizle güvenli bir şekilde hesabınıza giriş yapabilirsiniz.
            </Paragraph>
          </Row>
        </>
      ) : (
        <>
          <Row justify={"center"}>
            <img src={Logo} alt="Kontrol X" className='logo' height={40} style={{ margin: '20px 0 10px 0' }} />
          </Row>
          <Form onFinish={onFinishOtpAndNewPassword}>
            <Row align={'middle'} justify={'center'}>
              <Title level={2} className='form-header-title'>Yeni Şifre Oluştur</Title>
              <Paragraph className='fs-20 fw-400 font-color-subtext text-center'>
                Lütfen e-posta adresinize gelen tek kullanımlık parolayı ve yeni şifrenizi giriniz.
              </Paragraph>
            </Row>
            <Form.Item
              name='otp'
              label="Tek Kullanımlık Parola"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              rules={[{ required: true, message: 'Lütfen Tek Kullanımlık Parolayı giriniz!' }]}
            >
              <Input.OTP
                size='large'
                length={6}
                value={otp}
                onChange={setOtp}
                formatter={(str) => str.toUpperCase()}
                className='otp-input'
              />
            </Form.Item>
            <Form.Item
              name='newPassword'
              label="Yeni Şifre"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              rules={[
                { required: true, message: 'Lütfen yeni şifrenizi giriniz!' },
                {
                  validator: (_, value) => {
                    const errors = [];
                    if (!value) {
                      return Promise.reject(new Error('Şifre boş bırakılamaz!'));
                    }
                    if (value.length < 8) {
                      errors.push('Şifre en az 8 karakter olmalıdır!');
                    }
                    // if (!/[A-Z]/.test(value)) {
                    //   errors.push('En az bir büyük harf içermelidir!');
                    // }
                    // if (!/[a-z]/.test(value)) {
                    //   errors.push('En az bir küçük harf içermelidir!');
                    // }
                    if (!/[0-9]/.test(value)) {
                      errors.push('En az bir sayı içermelidir!');
                    }
                    if (errors.length > 0) {
                      return Promise.reject(new Error(errors.join('\n')));
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input.Password
                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                className='d-input'
                placeholder='Yeni Şifre'
              />
            </Form.Item>
            <Form.Item
              name='confirmPassword'
              dependencies={['newPassword']}
              label="Yeni Şifre Tekrarı"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              rules={[
                { required: true, message: 'Lütfen yeni şifrenizi tekrar giriniz!' },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('newPassword') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('Şifreler eşleşmiyor!'));
                  },
                }),
              ]}
            >
              <Input.Password
                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                className='d-input'
                placeholder='Yeni Şifre Tekrarı'
              />
            </Form.Item>
            <Form.Item>
              <Button
                htmlType='submit'
                className='d-button mt-16px'
                type='primary'
              >
                Değiştir
              </Button>
            </Form.Item>
          </Form>
        </>
      )}
      <p className='font-color-subtext fw-400 text-center'>
        Şifreni biliyor musun? <Link to={'/login'}>Giriş Yap</Link>
      </p>
    </Content>
  );
};

export default ForgotToNewPassword;
