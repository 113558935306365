import { Button, Form, Input, Checkbox, Divider, Row, Col, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PermissionService from '../../services/PermissonService';
import RoleService from '../../services/RoleService';
import { IPermissionResponseData } from '../../types/Permission';
import { openNotification } from '../../utils';

const { Title } = Typography;

interface RoleCreateProps {
    onClose: () => void;
    getRoles: () => void;
}

const RoleCreate: React.FC<RoleCreateProps> = ({ onClose, getRoles }) => {
    const [permissions, setPermissions] = useState<IPermissionResponseData[]>([]);
    const [groupedPermissions, setGroupedPermissions] = useState<{ [key: string]: IPermissionResponseData[] }>({});
    const [selectedPermissions, setSelectedPermissions] = useState<number[]>([]);
    const navigate = useNavigate();
    const [form] = Form.useForm();

    useEffect(() => {
        fetchPermissions();
    }, []);

    /**
     * Group (ör. 'roles', 'permission', 'attribute', 'company') -> Türkçe karşılığı
     */
    const translateGroup = (group: string): string => {
        switch (group) {
            case 'roles':
                return 'Rol';
            case 'permission':
                return 'Yetki';
            case 'attribute':
                return 'Parametre';
            case 'company':
                return 'Firma';
            default:
                return group; // Tanımsız grup için orijinal
        }
    };

    /**
     * Action (ör. create, delete, update, list, show...) -> Türkçe karşılığı
     */
    const translateAction = (action: string): string => {
        switch (action) {
            case 'create':
                return 'Ekleme';
            case 'delete':
                return 'Silme';
            case 'update':
                return 'Düzenleme';
            case 'list':
                return 'Görüntüleme';
            case 'show':
            case 'read':
                return 'Görüntüleme';
            default:
                return action; // Tanımlı olmayanlar için orijinalini göster
        }
    };

    /**
     * İzinleri sunucudan çekip gruplandırıyoruz
     */
    const fetchPermissions = () => {
        PermissionService.getAll()
            .then((response: any) => {
                const sortedData = [...response.data].sort((a, b) => a.name.localeCompare(b.name));
                setPermissions(sortedData);
                groupPermissions(sortedData);
            })
            .catch((error: Error) => {
                console.error('İzinler alınamadı', error);
            });
    };

    const groupPermissions = (perms: IPermissionResponseData[]) => {
        const grouped = perms.reduce((acc, permission) => {
            const [group] = permission.name.split('.');
            if (!acc[group]) {
                acc[group] = [];
            }
            acc[group].push(permission);
            return acc;
        }, {} as { [key: string]: IPermissionResponseData[] });

        setGroupedPermissions(grouped);
    };

    /**
     * Checkbox değişince selectedPermissions güncellenir.
     */
    const onChange = (checkedValues: number[]) => {
        setSelectedPermissions(checkedValues);
    };

    /**
     * Form submit
     */
    const onFinish = (values: any) => {
        const updatedValues = {
            ...values,
            permissionIds: selectedPermissions,
        };
        RoleService.create(updatedValues)
            .then(() => {
                openNotification('success', 'Başarılı', 'Rol başarıyla eklendi');
                getRoles();
                onClose();
            })
            .catch((error: Error) => {
                console.error('Rol oluşturulamadı', error);
                openNotification('error', 'Başarısız', 'Rol eklenirken hata oluştu');
            });
    };

    return (
        <Form onFinish={onFinish} layout="vertical" className="role-create-form" form={form}>
            <Form.Item
                name="name"
                label={<p style={{ fontSize: "16px", margin: 0, fontWeight: 'bold' }}>Rol Adı </p>}
                rules={[{ required: true, message: 'Lütfen rol adını giriniz!' }]}
            >
                <Input
                    placeholder="Lütfen Rol Adı Giriniz."
                    className='d-input d-input-form'
                />
            </Form.Item>

            {/* 
            <Form.Item
                name="description"
                label={<p style={{ fontSize: "16px", margin: 0, fontWeight: 'bold' }}>Rol Açıklaması </p>}
                rules={[{ required: true, message: 'Lütfen rol açıklaması giriniz!' }]}
            >
                <Input
                    placeholder="Lütfen Rol Açıklaması Giriniz."
                    className='d-input d-input-form'
                />
            </Form.Item>
            */}

            <Form.Item 
                name="permissions" 
                label={<p style={{ fontSize: "16px", margin: 0, fontWeight: 'bold' }}>Rol Yetkileri </p>}
            >
                <Divider style={{ marginTop: 16 }} />
                {Object.keys(groupedPermissions).map((group) => {
                    const groupTr = translateGroup(group); // Türkçe grup ismi
                    return (
                        <div key={group}>
                            <Row gutter={[16, 16]} className="permission-row">
                                <Col span={5}>
                                    <span style={{ fontWeight: 'bold' }}>{groupTr}</span>
                                </Col>
                                {groupedPermissions[group].map((permission) => {
                                    const actionPart = permission.name.split('.')[1];
                                    const label = translateAction(actionPart);
                                    const isChecked = selectedPermissions.includes(permission.id);

                                    return (
                                        <Checkbox
                                            key={permission.id}
                                            value={permission.id}
                                            checked={isChecked}
                                            onChange={(e) => {
                                                let newSelected = [...selectedPermissions];
                                                if (e.target.checked) {
                                                    newSelected.push(permission.id);
                                                } else {
                                                    newSelected = newSelected.filter(id => id !== permission.id);
                                                }
                                                onChange(newSelected);
                                            }}
                                        >
                                            {label}
                                        </Checkbox>
                                    );
                                })}
                            </Row>
                            <Divider />
                        </div>
                    );
                })}
            </Form.Item>

            <Form.Item>
                <Row justify={'center'}>
                    <Button 
                        type="primary" 
                        htmlType="submit" 
                        className="d-button width-auto dark-button"
                    >
                        Yeni Rol Ekle
                    </Button>
                </Row>
            </Form.Item>
        </Form>
    );
};

export default RoleCreate;