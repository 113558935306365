import { Button, Checkbox, Row, Table, Space, Popconfirm } from 'antd';
import React, { useState, useEffect } from 'react';
import AuthorizedPersonService from '../../services/AuthorizedPersonService';
import AuthorizedPersonDrawer from './AuthorizedPersonDrawer';
import { openNotification } from '../../utils'; // Varsayım: Bildirim için

import TrashIcon from '../../assets/icons/trash.svg';
import PenIcon from '../../assets/icons/pen.svg';

interface AuthorizedPerson {
  id: number;
  firstName: string;
  lastName: string;
  position: string;
  email: string;
  phoneNumber: string;
  default: boolean;
}

const AuthorizedPersonTable = ({
  companyId,
  setSelectedAuthorizedPersonIds,
  selectedAuthorizedPersonIds,
  type = false,
}: {
  companyId: number;
  setSelectedAuthorizedPersonIds: (ids: number[]) => void;
  selectedAuthorizedPersonIds: number[];
  type?: boolean;
}) => {
  const [authorizedPersons, setAuthorizedPersons] = useState<AuthorizedPerson[]>([]);
  const [selectedRows, setSelectedRows] = useState<number[]>(selectedAuthorizedPersonIds);

  // Drawer görünürlüğü ve düzenlenecek kişi
  const [isAuthorizedPersonDrawerVisible, setAuthorizedPersonDrawerVisible] = useState(false);
  const [editPerson, setEditPerson] = useState<AuthorizedPerson | null>(null);

  useEffect(() => {
    if (companyId) {
      fetchAuthorizedPersons();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  const fetchAuthorizedPersons = async () => {
    try {
      const response = await AuthorizedPersonService.getCompanyAuthorizedPersons(companyId);
      setAuthorizedPersons(response.data);
    } catch (error) {
      console.error('Yetkili kişiler yüklenirken hata oluştu:', error);
      openNotification('error', 'Hata', 'Yetkili kişiler yüklenemedi');
    }
  };

  // Checkbox satır seçme
  const handleCheckboxChange = (record: AuthorizedPerson) => {
    const updatedSelectedRows = selectedRows.includes(record.id)
      ? selectedRows.filter((id) => id !== record.id)
      : [...selectedRows, record.id];

    setSelectedRows(updatedSelectedRows);
    setSelectedAuthorizedPersonIds(updatedSelectedRows);
  };

  // Yeni Yetkili Ekle (Drawer'ı boş form ile açar)
  const handleAddPersonClick = () => {
    setEditPerson(null);
    setAuthorizedPersonDrawerVisible(true);
  };

  // Edit
  const handleEditPersonClick = (person: AuthorizedPerson) => {
    setEditPerson(person);
    setAuthorizedPersonDrawerVisible(true);
  };

  // Delete
  const handleDeletePerson = async (person: AuthorizedPerson) => {
    try {
      await AuthorizedPersonService.remove(person.id);
      openNotification('success', 'Başarılı', 'Yetkili başarıyla silindi');
      fetchAuthorizedPersons();
    } catch (error) {
      console.error('Yetkili silinirken hata oluştu:', error);
      openNotification('error', 'Hata', 'Yetkili silinemedi');
    }
  };

  // Drawer kapandığında listeyi yenile
  const handleAuthorizedPersonDrawerClose = () => {
    setAuthorizedPersonDrawerVisible(false);
    setEditPerson(null);
    fetchAuthorizedPersons();
  };

  const columns = [
    {
      title: '',
      width: 50,
      key: 'selection',
      render: (_text: string, record: AuthorizedPerson) => (
        <Checkbox
          checked={selectedRows.includes(record.id)}
          onChange={() => handleCheckboxChange(record)}
        />
      ),
    },
    {
      title: 'Ad',
      dataIndex: 'firstName',
      key: 'firstName',
      width: 260,
    },
    {
      title: 'Soyad',
      dataIndex: 'lastName',
      key: 'lastName',
      width: 260,
    },
    {
      title: 'Ünvan',
      dataIndex: 'position',
      key: 'position',
      width: 150,
    },
    {
      title: 'E-Posta',
      dataIndex: 'email',
      key: 'email',
      width: 250,
    },
    {
      title: 'Cep Telefonu',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
    },
    {
      title: 'İşlem',
      key: 'action',
      render: (_text: any, record: AuthorizedPerson) => (
        <Space>
          {/* Düzenle */}
          <Button
            icon={<img src={PenIcon} alt="pen" className="icon" />}
            shape="circle"
            className="icon-button"
            onClick={(e) => {
              e.stopPropagation(); // Tabloda satır seçimi engelle
              handleEditPersonClick(record);
            }}
          />

          {/* Sil */}
          <Popconfirm
            title="Bu yetkiliyi silmek istediğinizden emin misiniz?"
            onConfirm={(e) => {
              e?.stopPropagation();
              handleDeletePerson(record);
            }}
            okText="Evet"
            cancelText="Hayır"
            onCancel={(e) => e?.stopPropagation()}
          >
            <Button
              icon={<img src={TrashIcon} alt="trash" className="icon" />}
              shape="circle"
              className="icon-button"
              onClick={(e) => e.stopPropagation()} // Popconfirm açılmasını engellemeden satır seçimi durdurmak için
            />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Row justify="space-between">
        <h3>Yetkili Kişi</h3>
        <Button
          type="primary"
          className="d-button mb-16px width-auto dark-button"
          onClick={handleAddPersonClick}
        >
          Yeni Yetkili Ekle
        </Button>
      </Row>

      {type === true && (
        <div style={{ marginBottom: 16 }}>
          Teklifin gönderileceği yetkilileri seçiniz.
        </div>
      )}

      <Table
        scroll={{ x: 768 }}
        columns={columns}
        pagination={false}
        dataSource={authorizedPersons}
        rowKey={(record) => record.id.toString()}
        onRow={(record) => ({
          onClick: () => handleCheckboxChange(record),
        })}
      />

      <AuthorizedPersonDrawer
        visible={isAuthorizedPersonDrawerVisible}
        onClose={handleAuthorizedPersonDrawerClose}
        companyId={companyId as any}
        existingData={null}
        editPerson={editPerson}
      />
    </>
  );
};

export default AuthorizedPersonTable;