import { Button, Col, Form, Input, Row, Image, Typography, Layout } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import axios from 'axios';
import { openNotification } from '../../utils';
import './auth.scss';
import lockIcon from './../../assets/auth/LockOutlined.svg';
import Logo from '../../assets/auth/kontrolxlogo.svg';

const { Title, Text } = Typography;
const { Content } = Layout;

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);

  const onFinish = (values: { email: string }) => {
    setIsLoading(true);
    setEmail(values.email);

    let userData = {
      email: values.email,
    };

    const apiUrl = process.env.REACT_APP_API_BASE_URL + '/auth/reset-password-requests';

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: apiUrl,
      data: userData,
    };

    axios
      .request(config)
      .then((response: any) => {
        console.log(response);
        openNotification('success', 'Başarılı', 'E-Posta adresinizi kontrol ediniz.');
        // Yönlendirme ve e-postayı URL parametresi olarak ekleme
        navigate(`/forgot-password-confirm?email=${encodeURIComponent(values.email)}`);
        setIsLoading(false);
      })
      .catch((e: Error) => {
        console.log(e);
        openNotification('error', 'Başarısız', 'E-Posta adresiniz doğru değil! Kontrol ederek tekrar deneyin.');
        setIsLoading(false);
      });
  };

  return (
    <Content>
      <Row justify={'center'}>
        <img src={Logo} alt="Kontrol X" className='logo' height={40} style={{ margin: '20px 0 10px 0' }} />
      </Row>
      <Row justify={'center'} className='mb-4 mt-4'>
        <Image src={lockIcon} preview={false} width={64} />
      </Row>
      <Row align={'middle'} justify={'center'}>
        <Title level={2}>Şifremi Unuttum</Title>
        <p className='text-center'>
          Şifrenizi değiştirmek için kullandığınız e-posta adresini girin.
          Böylece şifrenizi sıfırlamak için size bir bağlantı gönderebiliriz.
        </p>
      </Row>

      <Form
        name='email_form'
        className='email-form'
        initialValues={{ remember: true }}
        onFinish={onFinish}
      >
        <Form.Item
          name='email'
          label="E-Posta"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          rules={[{ required: true, message: 'Lütfen e-posta adresininizi giriniz.' }]}
        >
          <Input
            className='d-input'
            placeholder='E-Posta'
          />
        </Form.Item>
        <Form.Item>
          <Button
            htmlType='submit'
            className='d-button mt-16px'
            type='primary'
            loading={isLoading}
          >
            Gönder
          </Button>
        </Form.Item>
      </Form>
      <p className=' font-color-subtext fw-400 text-center'>
        Şifreni biliyor musun? <Link to={'/login'}>Giriş Yap</Link>
      </p>
    </Content>
  );
};

export default ForgotPassword;
