import { Button, Modal, Form, Input, notification, Checkbox, ColorPicker, Row, Select } from 'antd';
import React, { useState } from 'react';
import StatusService from '../Service/StatusService';
import { IStatusRequest } from '../Type/Status';
interface CreateStatusModalProps {
    onClose: () => void;
    createVisible: boolean;
    refreshTrigger: () => void;
}

const CreateStatusModal: React.FC<CreateStatusModalProps> = ({ onClose, createVisible, refreshTrigger }) => {
    const [form] = Form.useForm();
    const [color, setColor] = useState<string>('1677ff');

    const handleCreateSubmit = (values: any): void => {
        const data: IStatusRequest = {
            name: values.name,
            description: values.description,
            category: values.category,
            color: color,
            default: values.default || false,
        };

        StatusService.create(data)
            .then(() => {
                refreshTrigger();
                form.resetFields();
                notification.success({
                    message: 'Başarılı',
                    description: 'Statü başarıyla eklendi.',
                });
                onClose();
            })
            .catch((e: Error) => {
                notification.error({
                    message: 'Hata',
                    description: 'Kaydetme sırasında bir hata oluştu.',
                });
                console.error('Kaydetme sırasında hata:', e);
            });
    };

    return (
        <Modal
            title="Statü Ekle"
            visible={createVisible}
            onCancel={onClose}
            footer={null}
        >
            <Form layout="vertical" form={form} onFinish={handleCreateSubmit}>
                <Form.Item label="Adı">
                    <Input.Group compact>
                        <Form.Item
                            name="name"
                            noStyle
                            rules={[{ required: true, message: 'Lütfen statü adını girin' }]}
                        >
                            <Input
                                className='d-input d-input-form'
                                style={{
                                    width: 'calc(100% - 48px)',
                                    borderRadius: '8px 0 0 8px',
                                }}
                                placeholder="Statü adı"
                            />
                        </Form.Item>
                        <Form.Item name="color" noStyle>
                            <ColorPicker
                                value={`#${color}` || '#1677ff'}
                                onChange={(value: any, hex: string) => setColor(`${hex}`)}
                                format="hex"
                                style={{
                                    width: '48px',
                                    height: '48px',
                                    borderRadius: '0 8px 8px 0',
                                }}
                                trigger="click"
                            />
                        </Form.Item>
                    </Input.Group>
                </Form.Item>
                <Form.Item name="description" label="Açıklama" rules={[{ message: 'Lütfen açıklama girin' }]}>
                    <Input.TextArea
                        className='d-input d-input-form'
                        placeholder="Statü Detay"
                    />
                </Form.Item>
                {/* <Form.Item name="category" label="Kategori" rules={[{ required: true, message: 'Lütfen bir kategori seçin' }]}>
                    <Select placeholder="Kategori seçin" className='d-input d-input-form'>
                        <Select.Option value="NEW">YENİ</Select.Option>
                        <Select.Option value="PROCESSING">HAZIRLANIYOR</Select.Option>
                        <Select.Option value="COMPLETED">TAMAMLANDI</Select.Option>
                    </Select>
                </Form.Item> */}
                {/* <Form.Item name="default" valuePropName="checked">
                    <Checkbox>Varsayılan Olarak Belirle</Checkbox>
                </Form.Item> */}
                <Form.Item>
                    <Row justify={'center'}>
                        <Button type="primary" htmlType="submit" block className='d-button dark-button width-auto'>
                            Kaydet
                        </Button>
                    </Row>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default CreateStatusModal;