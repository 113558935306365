import React, { useState, useEffect, useCallback } from "react";
import { Table, Button, Input, Avatar, Tooltip, Typography, Row, Col, Modal, Select } from "antd";
import { ColumnsType } from "antd/es/table";
import { SearchOutlined } from "@ant-design/icons";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { debounce } from 'lodash';
import CompanyService from "../../services/CompanyService";
import UserService from "../../services/UserService"; // Kullanıcıları çekmek için ekledik
import { capitalizeWords, formatDate, openNotification } from "../../utils";

const { Title, Text } = Typography;
const { Option } = Select;

interface Company {
  id: string;
  name: string;
  authorizedPersons: [{
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
  }];
  email: string;
  phoneNumber: string;
  creationDate: string;
  imageId?: string;
  logoUrl?: string;
}

const Companies: React.FC = () => {
  const [companies, setCompanies] = useState<Company[]>([]);
  const [filteredCompanies, setFilteredCompanies] = useState<Company[]>([]);
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [roles, setRoles] = useState<any>();
  const [isEditDrawerVisible, setIsEditDrawerVisible] = useState(false);
  const [selectedCompanyId, setSelectedCompanyId] = useState<string | null>(null);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  // URL'den companyId parametresini alıyoruz
  const { id } = useParams<{ id: string }>();

  const [isModalVisible, setIsModalVisible] = useState(false); // Modal kontrolü
  const [users, setUsers] = useState<any[]>([]);
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null); // Seçilen kullanıcı

  // Debounced search for users
  const debouncedSearchUsers = useCallback(
    debounce((value) => {
      if (value.length >= 1) {
        fetchUsers(value);
      } else {
        fetchUsers("");
      }
    }, 300),
    []
  );

  const handleUserSearch = (value: string) => {
    debouncedSearchUsers(value);
  };

  const debouncedSearch = useCallback(
    debounce((value) => {
      if (value.length >= 1) {
        setSearchTerm(value);
      } else {
        setSearchTerm("");
      }
    }, 300),
    []
  );

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toLowerCase();
    debouncedSearch(value);
  };

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        let storedRoles: any = await localStorage.getItem('roles');
        let parsedRoles = await JSON.parse(storedRoles);
        setRoles(parsedRoles);

        const response = await
          CompanyService.getAll({ search: searchTerm, page: currentPage, size: pageSize, accountId: id })

        const companiesWithLogo = await Promise.all(
          response.data.content.map(async (company: Company) => {
            if (company.imageId) {
              try {
                const logoResponse = await CompanyService.getImage(company.imageId);
                return { ...company, logoUrl: logoResponse.data.preSignUrl };
              } catch (error) {
                console.error("Logo yüklenirken hata oluştu:", error);
              }
            }
            return company;
          })
        );
        setCompanies(companiesWithLogo);
        setFilteredCompanies(companiesWithLogo);
        setTotal(response.data.totalElements);
      } catch (error) {
        console.error("Şirketler yüklenirken hata oluştu:", error);
      }
    };

    fetchCompanies();
  }, [isDrawerVisible, isEditDrawerVisible, searchTerm, currentPage, pageSize]);

  const fetchUsers = async (searchTerm: string = "", page: number = 0) => {
    try {
      const response: any = await UserService.getAll({ name: searchTerm, page: page, size: 10, sortDirection: '', sortField: '' });
      setUsers(response.data.content);
    } catch (error) {
      console.error("Kullanıcılar yüklenirken hata oluştu:", error);
    }
  };

  const handleTransfer = async () => {
    if (selectedCompanyId && selectedUserId) {
      try {
        await CompanyService.transfer(selectedCompanyId, selectedUserId);
        openNotification("success", "Başarılı", "Firma başarıyla transfer edildi.");
        setIsModalVisible(false);
        setSelectedUserId(null);
        setSelectedCompanyId(null);
        // Şirketleri tekrar yükleyelim
        const response = await
          CompanyService.getAll({ search: searchTerm, page: currentPage, size: pageSize, accountId: id })

        const companiesWithLogo = await Promise.all(
          response.data.content.map(async (company: Company) => {
            if (company.imageId) {
              try {
                const logoResponse = await CompanyService.getImage(company.imageId);
                return { ...company, logoUrl: logoResponse.data.preSignUrl };
              } catch (error) {
                console.error("Logo yüklenirken hata oluştu:", error);
              }
            }
            return company;
          })
        );
        setCompanies(companiesWithLogo);
        setFilteredCompanies(companiesWithLogo);
        setTotal(response.data.totalElements);
      } catch (error) {
        openNotification("error", "Hata", "Transfer işlemi başarısız oldu.");
        console.error("Transfer işlemi sırasında hata oluştu:", error);
      }
    }
  };

  const columns: ColumnsType<Company> = [
    {
      title: "Firma Adı",
      dataIndex: "name",
      key: "name",
      render: (text, record) => {
        const displayText = text.length > 28 ? `${text.substring(0, 28)}...` : text;
        return (
          <div className="d-flex align-items-center">
            <div className='avatar-image-logo' style={{ marginRight: 10 }}>
              {record.logoUrl ? (
                <img
                  src={record.logoUrl}
                  alt={record.name}
                  className='avatar-image-logo'
                  style={{ borderRadius: '50%' }}
                />
              ) : (
                <Avatar className='avatar-image-logo' style={{ backgroundColor: '#f0f0f0', color: '#000' }}>
                  {record.name.charAt(0).toUpperCase()}
                </Avatar>
              )}
            </div>
            <Tooltip title={text.toUpperCase()}>
              <span>{displayText.toUpperCase()}</span>
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: "Yetkili Ad Soyad",
      key: "authorizedPersons",
      render: (text, record) => {
        const defaultPerson = record.authorizedPersons.find((person: any) => person.default) || record.authorizedPersons[0];
        return defaultPerson ? `${capitalizeWords(defaultPerson.firstName)} ${capitalizeWords(defaultPerson.lastName)}` : "Yetkili Bulunamadı";
      },
    },
    {
      title: "Yetkili E-posta",
      dataIndex: "email",
      key: "email",
      render: (text, record) => {
        const defaultPerson = record.authorizedPersons.find((person: any) => person.default) || record.authorizedPersons[0];
        return defaultPerson ? defaultPerson.email : "Yetkili E-posta Bulunamadı";
      },
    },
    {
      title: "Yetkili Cep Telefonu",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      render: (text, record) => {
        const defaultPerson = record.authorizedPersons.find((person: any) => person.default) || record.authorizedPersons[0];
        return defaultPerson ? defaultPerson.phoneNumber : "Yetkili Telefon Bulunamadı";
      },
    },
    {
      title: "Oluşturma Tarihi",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => <Text>{formatDate(text)}</Text>,
    },
    {
      title: "İşlem",
      key: "action",
      render: (text, record) => (
        <div className="companies-action">
          <Button
            type="primary"
            className='d-button dark-button'
            style={{ marginRight: 10, width: 'auto' }}
            onClick={() => {
              setSelectedCompanyId(record.id);
              fetchUsers();
              setIsModalVisible(true);
            }}
          >
            Taşı
          </Button>
        </div>
      ),
    },
  ];

  const handlePaginationChange = (page: number, pageSize: number) => {
    setCurrentPage(page - 1);
    setPageSize(pageSize);
  };

  const showDrawer = () => {
    setIsDrawerVisible(true);
  };

  const closeDrawer = () => {
    setIsDrawerVisible(false);
  };

  const showEditDrawer = (companyId: string) => {
    setSelectedCompanyId(companyId);
    setIsEditDrawerVisible(true);
  };

  const closeEditDrawer = () => {
    setIsEditDrawerVisible(false);
    setSelectedCompanyId(null);
  };

  const handleSendOffer = (companyId: string) => {
    // accountId'yi URL'ye ekleyerek yönlendirme yapıyoruz
    navigate(`/companies/${companyId}?accountId=${id}`);
  };

  return (
    <>
      <Row gutter={[16, 16]} align="middle" justify="space-between" className="mb-120px">
        <Col xs={24} sm={12} lg={16}>
          <Title level={4} style={{ margin: 0 }}>
            Firma Listesi
          </Title>
        </Col>
        <Col xs={24} sm={12} lg={8} style={{ textAlign: 'center' }} className="d-flex justify-content-end">
          <Input
            placeholder="Ara"
            prefix={<SearchOutlined style={{ color: '#b8b9bc' }} />}
            className="d-input d-input-form d-input-filter"
            style={{ width: '100%', maxWidth: 200, marginRight: 16 }}
            onChange={handleSearch}
          />
          <Button type="primary" onClick={showDrawer} className="d-button dark-button width-auto br-30px">
            Yeni Firma Ekle
          </Button>
        </Col>
      </Row>
      <Table
        scroll={{ x: 768 }}
        columns={columns}
        dataSource={filteredCompanies}
        rowKey="id"
        pagination={{
          current: currentPage + 1,
          pageSize: pageSize,
          total: total,
          onChange: (page, pageSize) => {
            setCurrentPage(page - 1);
            setPageSize(pageSize || 10);
          },
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '30'],
        }}
      />

      {/* Transfer Modal */}
      <Modal
        title="Firma Transferi"
        visible={isModalVisible}
        footer={null}
        onCancel={() => setIsModalVisible(false)}
      >
        <Select
          showSearch
          placeholder="Kullanıcı Seç"
          optionFilterProp="children"
          onSearch={handleUserSearch}
          onChange={(value) => setSelectedUserId(value)}
          style={{ width: "100%", marginBottom: 16 }}
          filterOption={false}
        >
          {users.map((user) => (
            <Option key={user.id} value={user.id}>
              {user.firstName} {user.lastName} - {user.email}
            </Option>
          ))}
        </Select>
        <Row justify={'center'}>
          <Button type="primary" className='d-button dark-button width-auto'
            onClick={handleTransfer}>
            Transfer Et
          </Button>
        </Row>
      </Modal>
    </>
  );
};

export default Companies;