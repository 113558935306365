import { http } from "../http-common";

// Discounted offerings ile ilgili tüm listeyi getir
const getAllByOfferingId = (offeringId: number) => {
  return http.get<any[]>(`/offering/${offeringId}/discounted-offerings`);
};

// Tek bir discounted offering'ı ID ile getir
const getById = (id: number) => {
  return http.get<any>(`/discounted-offerings/${id}`);
};

// Yeni bir discounted offering oluştur
const create = (data: any) => {
  return http.post<any>(`/offerings/discounted-offerings`, data);
};

// Var olan discounted offering'ı güncelle
const update = (id: number, data: any) => {
  return http.put<any>(`/discounted-offerings/${id}`, data);
};

// Discounted offering'ı ID ile sil
const remove = (id: number) => {
  return http.delete<void>(`/discounted-offerings/${id}`);
};

const DiscountedOfferingService = {
  getAllByOfferingId,
  getById,
  create,
  update,
  remove,
};

export default DiscountedOfferingService;