import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import ProposalService from '../../services/ProposalService';

const JobTrackingChart: React.FC = () => {
    const [series, setSeries] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [categories, setCategories] = useState<string[]>([]);

    useEffect(() => {
        ProposalService.statusSummary()
            .then((response) => {
                const data: any = response.data;
                const statusNames = ['Tamamlanan', 'Devam Eden', 'Teklif Oluşturuldu', 'Teklif Aşamasında'];
                const statusData = [
                    data.Completed || 0,
                    data.inProposalStage || 0,
                    data.Ongoing || 0,
                    data.noOfferCreated || 0,
                ];

                setCategories(statusNames);
                setSeries([
                    {
                        name: 'Adet',
                        data: statusData
                    }
                ]);
                setLoading(false);
            })
            .catch((error) => {
                console.error('Durum özeti verisi alınamadı:', error);
                setLoading(false);
            });
    }, []);

    const options: any = {
        chart: {
            type: 'bar',
            height: 170,
            background: '#ffffff',
            toolbar: { show: false }
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '50%',
                distributed: true,
                // Sadece üst köşelerin yuvarlak olması:
                borderRadius: 8,
                borderRadiusApplication: 'end',
                dataLabels: {
                    position: 'top',
                    offsetY: -15
                },
            }
        },
        colors: ['#6E7B8B', '#D1D1D1', '#A9A9A9', '#4A90E2'],
        fill: {
            type: ['solid', 'pattern', 'pattern', 'solid'],
            pattern: {
                style: ['solid', 'slantedLines', 'dots', 'solid'],
                width: 6,
                height: 6,
                strokeWidth: 2,
            },
        },
        dataLabels: {
            enabled: true,
            formatter: (val: number) => `${val}`,
            style: {
                fontSize: '12px',
                colors: ['#001F3F'],
            }
        },
        xaxis: {
            categories: categories,
            labels: {
                show: true,
                trim: false,
                style: {
                    fontSize: '10px',
                    colors: ['#001F3F']
                }
            }
        },
        yaxis: {
            show: false,
            labels: {
                style: {
                    fontSize: '12px',
                    colors: ['#001F3F']
                }
            }
        },
        legend: { show: false },
        tooltip: {
            enabled: true,
            y: {
                formatter: (val: number) => `${val}`
            }
        },
    };

    return (
        <div>
            <h3 style={{ color: '#001F3F', margin: '0', fontSize: 24 }}>İş Takibi</h3>
            {!loading ? (
                series.length > 0 ? (
                    <Chart options={options} series={series} type="bar" height={190} />
                ) : (
                    <p style={{ color: '#999', fontSize: 18 }}>Henüz bir veriniz bulunmamakta.</p>
                )
            ) : (
                <p style={{ color: '#999', fontSize: 18 }}>Veri yükleniyor...</p>
            )}
        </div>
    );
};

export default JobTrackingChart;